import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material';
import { AuthService } from '../../auth/auth.service';
import { Router } from '@angular/router';
import { RegisterService } from './../../auth/+register/register.service';
import * as moment from 'moment';
import { GetDataPacienteService } from 'src/app/+reservas/get-data-paciente.service';
import { GetDataUserService } from 'src/app/+reservas/get-data-user.service';
import { ReservasService } from 'src/app/+reservas/reservas.service';
import { RegisterOkComponent } from '../register-ok/register-ok.component';
import * as shajs from 'sha.js';

@Component({
  selector: 'app-register-modal',
  templateUrl: './register-modal.component.html',
  styleUrls: ['./register-modal.component.scss']
})
export class RegisterModalComponent implements OnInit {
  serviceError;
  public color;
  public preloader: boolean = false;
  public dataReniecOk: boolean = false;
  public mailInvalid: boolean = false;
  public documentInvalid: boolean = false;
  // NAME
  public name: string = '';
  public nameReadyValidate: boolean = false;
  public nameValidate: boolean;

  // LAST NAME
  public lastName: string = '';
  public lastNameReadyValidate: boolean = false;
  public lastNameValidate: boolean;

  // LAST NAME
  public lastNameMaterno: string = '';
  public lastNameMaternoReadyValidate: boolean = false;
  public lastNameMaternoValidate: boolean;

  // SEXO
  public sexo: any = 'Sexo';
  public selectSexo: any;
  public sexoValidate: boolean = false;
  public sexoID;
  public sexoData;
  // TYPE DOCUMENT
  public document: any = 'Tipo de documento';
  public selectdocument: string = "D.N.I";
  public documentId = 1;
  public tipeDocumentService;
  public hideBox: boolean = false;

  // NUMBER DOCUMENT
  public documentNumber: any = '';
  public documentReadyValidate: boolean = false;
  public documentValidate: boolean;

  // DOCUMENT DIGIT
  public documentDigit: any = '';
  public documentReadyValidateDigit: boolean = false;
  public documentValidateDigit: boolean;

  // BIRTHDAY
  public birthday: any = '';
  public birthdayReadyValidate: boolean = false;
  public birthdayValidate: boolean;

  // NUMBER DOCUMENT
  public phoneNumber: any = '';
  public phoneReadyValidate: boolean = false;
  public phoneValidate: boolean;

  // EMAIL
  public email: string = '';
  public emailReadyValidate: boolean = false;
  public emailValidate: boolean = false;

  // PASSWORD
  public password = '';
  public passwordReadyValidate: boolean = false;
  public passwordValidate: boolean = false;

  // PASSWORD REPEAT
  public passwordRepeat: any = '';
  public passwordRepeatReadyValidate: any;
  public passwordRepeatValidate: boolean;
  public activeRepeat: boolean = false;

  public mayor: boolean = false;
  public mayus: boolean = false;
  public isNum: boolean = false
  public checked: any;
  numDoc: any;
  public activateDocumentNumber = true;

  // VAR OF STATE
  public loaderSession: boolean = false;

  // ID CODE
  public idCode: any;
  public dniInvalid: boolean = false;

  public mode: string = 'indeterminate';
  public dataResult;
  public dataReniec;
  public digitoVa: boolean = true;

  // EXPRESIONS REGULAR
  public ER_NUM = /^([0-9])*$/;
  public ER_ONLY_NUM: any = /[0-9]/;
  public ER_STR: any = /^[A-Za-z\_\-\.\s\xF1\xD1]+$/;
  public ER_STR_MA: any = /[A-Z]/;
  public ER_EMA = /[\w-\.]{3,}@([\w-]{2,}\.)*([\w-]{2,}\.)[\w-]{2,4}/;
  public numTelef = ['(+51)',/\d/, /\d/, /\d/, ' ',/\d/, /\d/, /\d/,  ' ',/\d/, /\d/, /\d/ ];
  public registerForm: boolean = false;

  constructor(public RegisterService: RegisterService, 
              public dialogRed: MatDialogRef<RegisterModalComponent>, 
              @Inject(MAT_DIALOG_DATA) 
              public message: string, 
              public dialog: MatDialog,
              public AuthService: AuthService, 
              public router: Router,
              public dataXhisSrv: GetDataPacienteService,
              public gdus: GetDataUserService,
              public reservasService: ReservasService) { }

  ngOnInit() {
    if (this.message === 'aviva-cuida') {

      document.querySelectorAll('body')[0].classList.add('aviva-cuida-modal');
      document.querySelectorAll('body')[0].classList.remove('aviva-cura-modal');
      document.querySelectorAll('body')[0].classList.remove('aviva-home');
    } else if (this.message === 'home' || this.message === 'reserva-doctor') {
      document.querySelectorAll('body')[0].classList.remove('aviva-cura-modal');
      document.querySelectorAll('body')[0].classList.remove('aviva-cuida-modal');
      document.querySelectorAll('body')[0].classList.add('aviva-home');
    } else if (this.message === 'aviva-cura') {
      document.querySelectorAll('body')[0].classList.add('aviva-cura-modal');
      document.querySelectorAll('body')[0].classList.remove('aviva-cuida-modal');
      document.querySelectorAll('body')[0].classList.remove('aviva-home');
    }
    else if (this.message === 'aviva-tele') {
      document.querySelectorAll('body')[0].classList.add('aviva-tele-modal');
      document.querySelectorAll('body')[0].classList.remove('aviva-tele-modal');
      document.querySelectorAll('body')[0].classList.remove('aviva-home');
    }
    this.passwordValidate = false;

    this.RegisterService.userGenders()
      .subscribe(data => {
        this.sexoData = data;
        console.log(this.sexoData)
      }, error => {
      });

    this.RegisterService.userDocuments()
      .subscribe((data:any) => {
        console.log('tipos de documento:',data);
        const filterdocument = data.filter(x =>x.id !== 4);
        this.tipeDocumentService = filterdocument;
      }, error => {

      })
  }


  //GET CODE
  getCode(email: any) {
    this.loaderSession = true;
    let hashdocumentnumber: any;
    let hashcelular: any;
    console.log('document type', this.document)
    this.RegisterService.sendCodeNew(this.email, this.documentNumber, this.documentId, this.selectdocument)
      .subscribe((data: any) => {
        if (data.result === 'ok') {
         /*  */
          const newFecha = this.birthday.split('/');
          const newFechaFormat: string = newFecha[2] + '-' + newFecha[1] + '-' + newFecha[0];
          this.onClickNo()
          this.idCode = data.id;
          this.RegisterService.data = {
            email: this.email,
            password: this.password,
            name: this.name,
            surname1: this.lastName,
            surname2: this.lastNameMaterno,
            birthdate: newFechaFormat,
            documentType: {
              id: this.documentId,
              name: this.selectdocument
            },
            documentNumber: this.documentNumber,
            phone: this.phoneNumber,
            code: 1234,
            id: this.idCode,
            origin:'web'
          }
          if(this.dataReniec){
            if(this.sexo === 'MASCULINO'){
              this.RegisterService.id = 2;
              this.RegisterService.name = 'HOMBRE';
            }else if(this.sexo === 'FEMENINO'){
              this.RegisterService.id = 3;
              this.RegisterService.name = 'MUJER';
            }else{
              this.RegisterService.id = 1;
              this.RegisterService.name = 'INDISTINTO';
            }
          }else{
            this.RegisterService.id = this.sexoID;
            this.RegisterService.name = this.selectSexo;
          }
          const code = 1234;
          this.RegisterService.registerNewUser(code).subscribe(data => {
            localStorage.setItem('session', JSON.stringify(data));
            this.welcomeAviva();
            if (this.message === 'home') {
              this.router.navigate(['/']);
            } else if (this.message === 'aviva-cuida') {
              this.router.navigate(['/reservas/avivacuida/seguro']);
            } else if (this.message === 'aviva-cura') {
              this.router.navigateByUrl('reservas/avivacura/seguro');
            } else if (this.message === 'reserva-doctor') {
              this.router.navigateByUrl('especialidades-doctores/reserva-doctor/valida-code');
            }
          })
        }
      }, error => {
        if (error.error.result === 'error') {
            if(error.message = "Ya tienes usuario web"){
              this.documentInvalid = true;
              this.loaderSession = false;
            }else{
              this.mailInvalid = true;
              this.loaderSession = false;
            }
        }
        this.loaderSession = false;
      })
      //inicio codigo analitycs
      hashdocumentnumber=shajs('sha256').update(this.documentNumber).digest('hex');
      hashcelular=shajs('sha256').update(this.phoneNumber).digest('hex');
      window.dataLayer=window.dataLayer || [];
      window.dataLayer.push({
      'event' : 'sign_up',
      'userId' : hashdocumentnumber,
      'hashcelular': hashcelular
      })
      //fin codigo analitycs
  }

  //SELECT GENDER
  selecGender(event) {
    this.selectSexo = event.target.selectedOptions[0].textContent;
    this.sexoID = event.target.value;
    if (this.sexo != this.selectSexo) {
      this.sexoValidate = true;
    } else {
      this.sexoValidate = false;
    }
  }

  selectDocument(event) {
    const documentType = event.target.selectedOptions[0].textContent;
    if (documentType === 'No Tiene') {
      this.hideBox = true;
    } else if(documentType === 'D.N.I'){
      this.registerForm = false;
      this.digitoVa = true;
        this.hideBox = false;
        this.documentNumber = '';
        this.selectdocument = event.target.selectedOptions[0].textContent;
        this.activateDocumentNumber = false;
        this.documentId = event.target.value;
    }else if(documentType === 'C.E.'){
      this.registerForm = true;
      this.digitoVa = false;
      this.hideBox = false;
      this.documentNumber = '';
      this.selectdocument = event.target.selectedOptions[0].textContent;
      this.activateDocumentNumber = false;
      this.documentId = 3;
      console.log(this.documentId);
    }else if(documentType === 'Pasaporte.'){
      this.registerForm = true;
      this.digitoVa = false;
      this.hideBox = false;
      this.documentNumber = '';
      this.selectdocument = event.target.selectedOptions[0].textContent;
      this.activateDocumentNumber = false;
      this.documentId = 2;
      console.log(this.documentId);
    }else{
      this.registerForm = true;
      this.digitoVa = false;
      this.hideBox = false;
      this.documentNumber = '';
      this.selectdocument = event.target.selectedOptions[0].textContent;
      this.activateDocumentNumber = false;
      this.documentId = event.target.value;
    }

  }

  activeRepeatPass() {
    if (this.passwordValidate === false) {
      this.activeRepeat = true;
    }
  }

  validatePass() {
      this.validateLen();
      if (this.mayor) {
        this.passwordValidate = true;
        this.activeRepeat = false; 
      } else {
        this.passwordValidate = false;
        this.activeRepeat = true;
      }
  }

  validaNum() {
    if (this.validateInput(this.ER_ONLY_NUM, this.password)) {
      this.isNum = true;
    } else {
      this.isNum = false;
    }
  }

  validateMayus() {
    if (this.validateInput(this.ER_STR_MA, this.password)) {
      this.mayus = true;

    } else {
      this.mayus = false;
    }
  }

  validateLen() {
    if (this.password.length >= 8) {
      this.mayor = true;
    } else {
      this.mayor = false;
    }
  }


  // VALIDATOR NUMBER

  // VALIDATOR STRING
  onkeyValidateString(data: any): void {
    if (this.nameReadyValidate && data === this.name) {
      if (data === this.name) {
        this.blurValidateString(data);
      }
    }

    else if (this.lastNameReadyValidate && data === this.lastName) {
      if (data === this.lastName) {
        this.blurValidateString(data);
      }
    }

    else if (this.lastNameMaternoReadyValidate && data === this.lastNameMaterno) {
      if (data === this.lastNameMaterno) {
        this.blurValidateString(data);
      }
    }

    else if (this.documentReadyValidate && data === this.documentNumber) {
      if (data === this.documentNumber) {
        this.blurValidateString(data);
      }
    }

    else if (this.phoneReadyValidate && data === this.phoneNumber) {
      if (data === this.phoneNumber) {
        this.blurValidateString(data);
      }
    }

  }

  blurValidateString(data: any): void {

    if (data === this.name) {
      this.nameReadyValidate = true;
      if (this.validateInput(this.ER_STR, data)) {
        this.nameValidate = true;
      } else {
        this.nameValidate = false;
      }
    }

    else if (data === this.lastName) {
      this.lastNameReadyValidate = true;
      if (this.validateInput(this.ER_STR, data)) {
        this.lastNameValidate = true;
      } else {
        this.lastNameValidate = false;
      }
    }

    else if (data === this.lastNameMaterno) {
      this.lastNameMaternoReadyValidate = true;
      if (this.validateInput(this.ER_STR, data)) {
        this.lastNameMaternoValidate = true;
      } else {
        this.lastNameMaternoValidate = false;
      }
    }

    else if (data === this.documentNumber) {
      this.documentReadyValidate = true;
      if (this.validateInput(this.ER_NUM, data)) {
        this.documentValidate = true;
      } else {
        this.documentValidate = false;
      }
    }

    else if (data === this.phoneNumber) {
      this.phoneReadyValidate = true;
      if (this.validateInput(this.ER_NUM, data) && data.length > 8) {
        this.phoneValidate = true;
      } else {
        this.phoneValidate = false;
      }
    }

    else if (data === this.email) {
      this.emailReadyValidate = true;
      if (this.validateInput(this.ER_EMA, data)) {
        this.emailValidate = true;
      } else {
        this.emailValidate = false;
      }
    }

  }
  // CLOSE MODAL REGISTER
  onClickNo() {
    this.dialogRed.close();
  }

  // VALIDATE INPUTS EXPRESIONES REGULARES
  validateInput(expresion: any, input: string): boolean {
    if (expresion.test(input)) {
      return true;
    } else {
      return false;
    }
  }

  // VALIDATE BIRTHDATE
  validateB() {
    let date = moment(this.birthday, 'DD-MM-YYYY').isValid();
    if (this.birthday.length === 10) {
      if (date) {
        this.birthdayValidate = true;
        this.birthdayReadyValidate = true;
      } else {
        this.birthdayValidate = false;
        this.birthdayReadyValidate = true;
      }
    } else {
      this.birthdayValidate = false;
    }
  }


  validateAllInputs(): boolean {
    if (this.nameValidate && this.lastNameValidate && this.lastNameMaternoValidate && this.documentValidate && this.birthdayValidate && this.phoneValidate && this.emailValidate && this.checked && this.passwordValidate && this.sexoValidate) {
      return false;
    } else {
      return true;
    }
  }

  sendForm(): void {
    this.getCode(this.email);
  }

  validateBirthdate() {
    const h = '/'
    if (this.birthday.length === 2) {

      this.birthday = this.birthday + h;
    } else if (this.birthday.length === 5) {

      this.birthday = this.birthday + h;
    } else if (this.birthday.length > 5) {



    }
    if (this.birthdayReadyValidate === true) {

      this.validateB();
    }
  }

  searchDniReniec(){
    this.preloader = true;
    console.log(this.documentNumber, this.documentDigit , this.documentId);
    this.gdus.getPublicKey(this.documentNumber).subscribe((data:any) => {
      this.dataReniec = data.data;
      console.log('this.dataReniec:',this.dataReniec);
        this.preloader = false;
        this.reservasService.dataPacienteReniec = this.dataReniec;
        this.registerForm = true;
        this.name = this.dataReniec.nombres;
        this.lastName = this.dataReniec.apellido_paterno;
        this.lastNameMaterno = this.dataReniec.apellido_materno;
        this.sexo = this.dataReniec.sexo;
        this.birthday = moment(this.dataReniec.fecha_nacimiento).format('D/MM/yyyy');
        this.nameValidate = true;
        this.lastNameValidate = true;
        this.lastNameMaternoValidate = true;
        this.sexoValidate = true;
        this.birthdayValidate = true;
        if(this.documentNumber == this.dataReniec.numero  && this.documentDigit == this.dataReniec.codigo_verificacion){
          this.registerForm = true;
        }else{
          this.dniInvalid = true;
          this.registerForm = false;
        }
  }, err => {
    this.dniInvalid = true;
    this.registerForm = true;
    console.log(err)
  })
  }

  welcomeAviva(){
    this.dialog.open(RegisterOkComponent)
  }

  soloNumeros(e){
    let tecla = (document.all)?e.keyCode:e.which;
    if(tecla==8){
      return true;
    }
    let patron  = /[0-9]/;
    let tecla_final = String.fromCharCode(tecla);
    return patron.test(tecla_final);
  }
  soloLetras(e){
    let tecla = (document.all)?e.keyCode:e.which;
    if(tecla==8){
      return true;
    }
    let patron  = /[A-Za-záÁéÉíÍóÓúÚñÑ ]/;
    let tecla_final = String.fromCharCode(tecla);
    return patron.test(tecla_final);
  }
}
