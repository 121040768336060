import { Component, OnInit } from '@angular/core';
import { AuthService } from '../auth/auth.service';
import { EspecialidadesDoctoresService } from '../../+especialidades-y-doctores/especialidades-doctores.service';
import { PlanesProgramasService } from '../../+planes-programas/planes-programas.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  defaultImage = 'data:image/jpeg;base64,/9j/4AAQSkZJRgABAQAAAQABAAD/2wCEAAEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAf/AABEIAAcACgMBEQACEQEDEQH/xAGiAAABBQEBAQEBAQAAAAAAAAAAAQIDBAUGBwgJCgsQAAIBAwMCBAMFBQQEAAABfQECAwAEEQUSITFBBhNRYQcicRQygZGhCCNCscEVUtHwJDNicoIJChYXGBkaJSYnKCkqNDU2Nzg5OkNERUZHSElKU1RVVldYWVpjZGVmZ2hpanN0dXZ3eHl6g4SFhoeIiYqSk5SVlpeYmZqio6Slpqeoqaqys7S1tre4ubrCw8TFxsfIycrS09TV1tfY2drh4uPk5ebn6Onq8fLz9PX29/j5+gEAAwEBAQEBAQEBAQAAAAAAAAECAwQFBgcICQoLEQACAQIEBAMEBwUEBAABAncAAQIDEQQFITEGEkFRB2FxEyIygQgUQpGhscEJIzNS8BVictEKFiQ04SXxFxgZGiYnKCkqNTY3ODk6Q0RFRkdISUpTVFVWV1hZWmNkZWZnaGlqc3R1dnd4eXqCg4SFhoeIiYqSk5SVlpeYmZqio6Slpqeoqaqys7S1tre4ubrCw8TFxsfIycrS09TV1tfY2dri4+Tl5ufo6ery8/T19vf4+fr/2gAMAwEAAhEDEQA/APqaP4a/twfCLwn4jn/ag8P/AA78YWum61H42tNE8N3vwn0DXtX8Y26+FpZNR1FND+FF/wCHrLVtP0/wo3hu28RaR4j03Vb7RtVWK8utmmaell8zKcKEVD3rxXJyJuMab+GM4cui5Xy2Vn8K1VrHtSnXxlWricT/ALRia9edeviKjVStiKtSTnUnXqVFz1Ks23z1JTc5OTcpSvdfE9t+0R8RJra3mh/Zy8E6TFLBFJFpVz470ie50yN41ZNPuJ7Dwh9gmnslItpZbL/RJJI2e2/clK8aecYKE5wlia/NCUoy9yb96LaevJrqtz9ZwXg3x3j8HhMdhcmy6eGxuFoYvDzlmeCpylQxNKFalKUHO8JSpzi3B6xbs9Uf/9k=';
  public dataContac: any = {
    contacts: [
{
  email: ''
}
    ]
    
  }
  public showData: boolean;
  dataSpecialty;
  dataPlans;
  public dataSpecialtyCuida;
  public dataSpecialtyCura;
  

  constructor(private auth: AuthService, private specialtyService : EspecialidadesDoctoresService, private planesServices: PlanesProgramasService) { }

  ngOnInit() {
    this.showData = false;
    this.auth.getInfoContact().subscribe((data: any) => {
      this.dataContac = data
      this.showData = true;
    })

    this.specialtyService.getSpecialty().subscribe((data:any) => {
      const dataSpecialty = data.data;
      this.dataSpecialtyCuida = dataSpecialty.filter(x => x.id == 1  || x.id == 2 || x.id == 3 || x.id == 4 || x.id == 5);
      this.dataSpecialtyCura = dataSpecialty.filter(x => x.id != 1  && x.id != 2 && x.id != 3 && x.id != 4 && x.id != 5);
    }, (error: any) => {
      console.log(error)
    })

    this.planesServices.getPlans().subscribe((data: any) => {
      const filtrados = data.data
      //quitando planes de niño sano y chequeo
      this.dataPlans = filtrados.filter(x => x.id === '1' || x.id === '2');
    }, error => {

    })

  }

}
