import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-errors',
  templateUrl: './errors404.component.html',
  styleUrls: ['./errors404.component.sass']
})
export class Errors404Component implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
