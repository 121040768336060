import { Component, OnInit } from '@angular/core';
import { DependensService } from 'src/app/+video/dependens.service';
import { MatDialog } from '@angular/material';
import { CreateParentComponent } from 'src/app/shared/modal/create-parent/create-parent.component';
import { DeleteParentComponent } from 'src/app/shared/modal/delete-parent/delete-parent.component';
import { ReservasService } from 'src/app/+reservas/reservas.service';

@Component({
  selector: 'app-mis-dependientes',
  templateUrl: './mis-dependientes.component.html',
  styleUrls: ['./mis-dependientes.component.scss']
})
export class MisDependientesComponent implements OnInit {
  public parents;

  constructor(public dependentsServ: DependensService,
    public modal: MatDialog,
    public reservaSrv: ReservasService) {
  }

  ngOnInit() {
    this.getAlldependends();

  }

  getAlldependends() {
    this.dependentsServ.getDependens().subscribe(data => {
      this.parents = data
    })
  }

  createParent() {
    //abrir modal con creación de familiar
    const dialogRef = this.modal.open(CreateParentComponent);

    dialogRef.afterClosed().subscribe(res => {
      this.parents = res.data;
      console.log(res.data) // received data from confirm-component
    })
  }

  deleteDependent(depe) {
    this.reservaSrv.idParent = depe._id;
    let dialogRef = this.modal.open(DeleteParentComponent);

    dialogRef.afterClosed().subscribe(res => {
      this.parents = res.data;
      console.log(res.data) // received data from confirm-component
    })
  }

}
