import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../shared/auth/auth.service';

@Component({
  selector: 'app-terminos-y-condiciones',
  templateUrl: './terminos-y-condiciones.component.html'
})
export class TerminosYCondicionesComponent implements OnInit {

  public dataTerms: any = '';

  constructor(private auth : AuthService) { }

  ngOnInit() {
    this.auth.getTermOfService().subscribe((data: any) => {
      this.dataTerms = data.termsOfService;
    })
  }

}
