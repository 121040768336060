import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog, MatSnackBar } from '@angular/material';
import { AuthService } from '../../auth/auth.service';
import { Router } from '@angular/router';
import { RegisterService } from './../../auth/+register/register.service';
import { LoginOkComponent } from '../login-ok/login-ok.component';
import * as shajs from 'sha.js';
declare var gtag;
declare global {
  interface Window {
    dataLayer: any;
  }
}
@Component({
  selector: 'app-modal-template',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss']
})
export class ModalComponent implements OnInit {
  
  public recoverPassword: boolean = false;
  public logins: boolean = false;
  public email: string = '';
  public emailValidate: boolean;
  public dni: string = '';
  public dniValidate: boolean;
  public password: string = '';
  public passValidate: boolean;
  public validatePasss: boolean;
  public preloader: boolean = false;
  public validate: boolean = false;
  public validateDni: boolean = false;
  public readyValidate: boolean = false;
  public readydniValidate: boolean = false;
  public showRegister: boolean = false;

  public loaderSession: boolean = false;
  public mode: string = 'indeterminate';
  public url: string = 'api/v2/users/login';
  public app = 'ebooking';
  public color;

  public document: any = 'Tipo de documento';
  public selectdocument: string = "D.N.I";
  public documentId = 1;
  public tipeDocumentService;
  public hideBox: boolean = false;

  // NUMBER DOCUMENT
  public documentNumber: any = '';
  public documentReadyValidate: boolean = false;
  public documentValidate: boolean;

  public registerForm;
  public digitoVa;
  public activateDocumentNumber: boolean=false;
  public documentInvalid: boolean = false;

  public serviceError: boolean;
  public validateRecovery: boolean = false;
  public nombreDoc:string;
  // EXPRESIONS REGULAR
  public ER_NUM = /^([0-9])*$/;
  public ER_STR: any = /^[A-Za-z\_\-\.\s\xF1\xD1]+$/;
  public ER_STR_MA: any = /[A-Z]/;
  public ER_EMA = /[\w-\.]{3,}@([\w-]{2,}\.)*([\w-]{2,}\.)[\w-]{2,4}/;



  constructor(public dialogRed: MatDialogRef<ModalComponent>, @Inject(MAT_DIALOG_DATA) 
              public message: string, @Inject(MAT_DIALOG_DATA) 
              public data: any, 
              public AuthService: AuthService, 
              public router: Router,
              public dialog: MatDialog,
              private snackBar: MatSnackBar,
              public RegisterService: RegisterService) {
                this.nombreDoc='DNI';
  }

  ngOnInit() {
    this.logins = true;
    if (this.message === 'aviva-cuida') {

      document.querySelectorAll('body')[0].classList.add('aviva-cuida-modal');
      document.querySelectorAll('body')[0].classList.remove('aviva-cura-modal');
      document.querySelectorAll('body')[0].classList.remove('aviva-home');
    } else if (this.message === 'home' || this.message === 'reserva-doctor') {
      document.querySelectorAll('body')[0].classList.remove('aviva-cura-modal');
      document.querySelectorAll('body')[0].classList.remove('aviva-cuida-modal');
      document.querySelectorAll('body')[0].classList.add('aviva-home');
    } else if (this.message === 'aviva-cura') {
      document.querySelectorAll('body')[0].classList.add('aviva-cura-modal');
      document.querySelectorAll('body')[0].classList.remove('aviva-cuida-modal');
      document.querySelectorAll('body')[0].classList.remove('aviva-home');
    }

    this.RegisterService.userDocuments()
    .subscribe(data => {
      this.tipeDocumentService = data;

    }, error => {

    })
  }

  onClickNo() {
    this.dialogRed.close();
  }

  // VALIDATE EMAIL KEYUP && BLUR
   onkeyBlurEmail() {
    if (this.email.length > 1) {
      this.readyValidate = true;
      this.onkeyEmail();
    }
  } 

  onkeyEmail() {
   this.serviceError = false;
   if (this.readyValidate) {
     if (this.validateInput(this.ER_EMA, this.email)) {
       this.emailValidate = false;
     } else {
       this.emailValidate = true;
     }
   }
 } 

  onkeyBlurDni() {
    if (this.dni.length > 7) {
      this.validateDni = true;
      this.onkeyDni();
    }else{
      this.validateDni=false;
    }
  }

  onkeyDni() {
    this.serviceError = false;
    if (this.validateDni) {
      this.dniValidate = true;
      this.validateRecovery = true;
    }else{
      this.dniValidate = false;
    }
  }


  // VALIDATE PASSWORD KEYUP && BLUR
  onkeyPass() {
    this.serviceError = false;
    this.validateInputSim(this.password)

  }

  // VALIDATE SIMPLE
  validateInputSim(input: any) {
    if (input.length > 3) {
      this.validatePasss = true;
      this.passValidate = true;
    } else if(input.length < 4 &&  input.length>0) {
      this.passValidate = false;
    }else{
      this.validatePasss = false;
    }
  }

  // VALIDATE INPUTS EXPRESIONES REGULARES
  validateInput(expresion: any, input: string): boolean {
    this.validateDni = true;
    if (expresion.test(input)) {
      this.dniValidate = true;
      this.validateDni = false;
      return false;
    }
  }

  ValidateInputs(): boolean {
    if (this.validateDni && this.passValidate) {
      return true;
    } else {
      return false;
    }
  }

  onkeyValidateString(data: any): void {
    if (this.documentReadyValidate && data === this.documentNumber) {
      if (data === this.documentNumber) {
        this.blurValidateString(data);
      }
    }
  }


blurValidateString(data: any): void {
  if (data === this.documentNumber) {
    this.documentReadyValidate = true;
    if (this.validateInput(this.ER_NUM, data)) {
      this.documentValidate = true;
    } else {
      this.documentValidate = false;
    }
  }
}


  // LOGIN
  login(event: any) {
    event.preventDefault();
    let hashdni: any;
    this.loaderSession = true;

    this.AuthService.newLoginPass( this.documentId, this.dni, this.password, this.app)
      .subscribe(data => {
        if (data.patientName) {
          this.openExito();
          this.loaderSession = false;
         // hashdni=this.dni;
          hashdni=shajs('sha256').update(this.dni).digest('hex')
          if (this.AuthService.isUser() === 'user') {
            // gtag("event", "login", {
            //   method: "Google"
            // });         
         //   (<any>window).dataLayer = (<any>window).dataLayer  || [];
            window.dataLayer = window.dataLayer  || [];
            window.dataLayer.push({
            'event' : 'login_gtm',
            'userId' : hashdni
            })
              
            if (this.message === 'aviva-cuida') {
              this.router.navigate(['/reservas/avivacuida/seguro']);
            }
            else if (this.message === 'aviva-cura') {
              this.router.navigateByUrl('reservas/avivacura/seguro');
            } else if (this.message === 'home') {
              this.router.navigate(['/']);
            } else if (this.message === 'reserva-doctor') {
              this.router.navigateByUrl('especialidades-doctores/reserva-doctor/financiador');
            }
            else {
              this.dialogRed.close();
            }
            this.dialogRed.close();
          }
        } else {
          if (data.status === 401) {
            this.loaderSession = false;
            this.serviceError = true;
            this.dniValidate = false;
            this.passValidate = false;
            this.dni = '';
            this.password = '';
            console.log('hubo un error de autenticacion')
          }
        }
      },
        (error) => {
          this.loaderSession = true;
            this.serviceError = true;
            this.dniValidate = false;
          console.log(error)
        });
  }

  sendRecover() {
    this.loaderSession = true;
    const dataSend = {
      documentNumber: this.dni,
      documentType: {
        id:this.documentId.toString(),
        name:this.selectdocument
      }
    }
    console.log(dataSend)
    this.AuthService.validateEmail(dataSend).subscribe((data: any) => {
      console.log(data);
      if (data.result === 'ok') {
        this.loaderSession = false;
        this.AuthService.idRecovery = data.id;
        this.AuthService.emailRecovery = this.email;
        this.AuthService.dni = this.dni;
        const documentType = {
          id:this.documentId,
          name:this.selectdocument
        }
        this.AuthService.documentType = documentType;
        this.AuthService.emailHash = data.emailHash;
        this.router.navigate(['/recuperar-contraseña', this.message]);
        this.onClickNo();
      }
      console.log(data.result)
    }, (error: any) => {
      if (error.error.result === 'error') {
        this.email = '';
        this.emailValidate = false;
        this.serviceError = true;
        this.loaderSession = false;
      }
      console.log(error.error.result)
    })
    // this.router.navigate(['/recuperar-contraseña']);
    // this. onClickNo();
    console.log(this.email)
  }

  selectDocument(event) {
    const documentType = event.target.selectedOptions[0].textContent;
    if (documentType === 'No Tiene') {
      this.hideBox = true;
    } else if(documentType === 'D.N.I'){
        this.documentNumber = '';
        this.selectdocument = event.target.selectedOptions[0].textContent;
        this.activateDocumentNumber = false;
        this.documentId = event.target.value;
        this.nombreDoc="DNI";
    }else if(documentType === 'C.E.'){
      this.documentNumber = '';
      this.selectdocument = event.target.selectedOptions[0].textContent;
      this.activateDocumentNumber = false;
      this.documentId = 3;
      this.nombreDoc="C.E.";
      console.log(this.documentId);
    }else if(documentType === 'Pasaporte.'){
      this.documentNumber = '';
      this.selectdocument = event.target.selectedOptions[0].textContent;
      this.activateDocumentNumber = false;
      this.documentId = 2;
      this.nombreDoc="pasaporte";
      console.log(this.documentId);
    }else{

      this.documentNumber = '';
      this.selectdocument = event.target.selectedOptions[0].textContent;
      this.activateDocumentNumber = false;
      this.documentId = event.target.value;
    }
    console.log(this.document)
  }

  showModalRegister() {
    this.showRegister = true;
    this.logins = false;
  }

  showRecovPass() {
    this.recoverPassword = true;
    this.logins = false;
    this.dni = "";
    this.document = 1;
  }

  openExito(){
    this.snackBar.openFromComponent(LoginOkComponent,{duration:2000,
      horizontalPosition: 'end'})
  }
  cargarLabel(){
    console.log(this.document)
  }

  soloNumeros(e){
    let tecla = (document.all)?e.keyCode:e.which;
    if(tecla==8){
      return true;
    }
    let patron  = /[0-9]/;
    let tecla_final = String.fromCharCode(tecla);
    return patron.test(tecla_final);
  }
}
