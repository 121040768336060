import { Component, OnInit, Input  } from '@angular/core';
import { ReservasService } from '../../../+reservas/reservas.service';
import { Observable } from 'rxjs';
import 'rxjs';


@Component({
  selector: 'app-app-layout-reservas',
  templateUrl: './app-layout-reservas.component.html',
  styleUrls: ['./app-layout-reservas.component.sass']
})
export class AppLayoutReservasComponent implements OnInit {

  barra: any;

  constructor(private ReservasService : ReservasService) { }

  ngOnInit() {

  }


  backLink(){
    window.history.back();
  };
}
