import { Injectable } from '@angular/core';
import { AuthService } from '../shared/auth/auth.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class EspecialidadesDoctoresService {
  

  constructor(public Authservice : AuthService, public http: HttpClient) { }
  urlBase = this.Authservice.getUrlBase();
  urlBaseNodos = this.Authservice.getUrlBaseNodos();

  getProfesionals(){
    const session = JSON.parse(localStorage.getItem('session'));
    let headers = new HttpHeaders({ "Authorization": session.authorization });
    return this.http
      .get(this.urlBase + 'api/v2/ebooking/fmt-centers/1/services/all/professionals' , {headers})
  }

  getSpecialty(){
    const session = JSON.parse(localStorage.getItem('session'));
    let headers = new HttpHeaders({ "Authorization": session.authorization });
    return this.http
      .get(this.urlBaseNodos + 'specialties' , {headers})
  }

  getDataSpecialty(data: any){
    const session = JSON.parse(localStorage.getItem('session'));
    let headers = new HttpHeaders({ "Authorization": session.authorization });
    return this.http  
      .get(this.urlBaseNodos + 'specialties/' + data , {headers});
  }

  getInfoPlans(data: any): any {
    const session = JSON.parse(localStorage.getItem('session'));
    let headers = new HttpHeaders({ "Authorization": session.authorization });
    return this.http
      .get(data , {headers})
  }

  getDoctorsSpecialty(params: any) {
    const session = JSON.parse(localStorage.getItem('session'));
    let headers = new HttpHeaders({ "Authorization": session.authorization });
    return this.http
      .get(this.urlBase + 'api/v2/ebooking/fmt-centers/1/services/' + params + '/professionals', {headers} );
  }

  getDoctorsDispo(specialty: any, profesional: any, date1: any,  date2: any) {
    const session = JSON.parse(localStorage.getItem('session'));
    let headers = new HttpHeaders({ "Authorization": session.authorization });
    return this.http
      .get(this.urlBase + 'api/v2/ebooking/fmt-centers/1/services/' + specialty + '/professionals/' + profesional +  '/availables?from_date=' + date1 + '&to_date=' + date2 , {headers});
  }

  getAllDoctorsSpecialty(doctors, desde, hasta){
    const session = JSON.parse(localStorage.getItem('session'));
    let headers = new HttpHeaders({ "Authorization": session.authorization });
    return this.http
        .get(this.urlBase + `api/v2/ebooking/fmt-centers/1/services/${doctors}/professionals/all/availables?from_date=${desde}&to_date=${hasta}`, {headers});
  }
}
