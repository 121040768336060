import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthService } from '../../shared/auth/auth.service';
import * as moment from 'moment';
import { MatDialog, MatDialogConfig } from '@angular/material';
import { AlertCitasComponent } from '../../shared/modal/alert-citas/alert-citas.component'
import { PermissionsVideoService } from 'src/app/+video/permissions-video.service';
import { ReservasService } from 'src/app/+reservas/reservas.service';
import { NoCancelComponent } from 'src/app/shared/modal/no-cancel/no-cancel.component';


@Component({
  selector: 'app-mis-citas-details',
  templateUrl: './mis-citas-details.component.html',
  styleUrls: ['./mis-citas-details.component.scss']
})
export class MisCitasDetailsComponent implements OnInit {

  public color: any = 'accent';
  public mode: any = 'indeterminate';
  public preloader: any;
  user;
  urlBase;
  show;
  public idCita: any;
  public data: any;
  public appointmentid: any;
  public appointmenttitular: any;
  public appointmentfamiliar: any;
  public horatitular: any;
  public horafamiliar: any;
  citaFilter: any;
  public infoCita;

  constructor(public dialog: MatDialog,
    private auth: AuthService,
    private router: Router,
    private activateRouter: ActivatedRoute,
    private permissionService: PermissionsVideoService,
    private reservaService: ReservasService) { }

  ngOnInit() {
    this.appointmenttitular=0;
    this.appointmentfamiliar=0;
    this.horatitular=0;
    this.horafamiliar=0;
    this.preloader = true;
    console.log(this.reservaService.infoCita)
    this.infoCita = this.reservaService.infoCita;
    if(this.infoCita){
      this.data = this.infoCita;
      console.log('this.infoCita:', this.data);
      if(this.data.parent==true){
        console.log("es un familiar");
        this.horafamiliar=1;
        this.appointmentfamiliar=1;
      }else if(this.data.parent==false){
        console.log("es un titular");
        this.horatitular=1;
        this.appointmenttitular=1;
      }

    }else{
        this.router.navigate(['mis-citas'])
    }
    console.log('pagina mis citas');
    this.urlBase = this.auth.urlBaseAlter;
    this.preloader = false;
    /*  this.activateRouter.params.subscribe((data: any) => {
      this.idCita = parseInt(data.id);
      console.log(data)
    }) */

    // this.idCita.appointmentDateTime = 

    /* if (this.idCita > 0) {
      this.auth.getCitasUser().subscribe((data: any) => {
        console.log('citas pendientrs', data);
        this.citaFilter = data.filter((element) => {
          return element.appointmentId === this.idCita;
        })

        this.data = this.citaFilter[0];
        this.data.hour = moment(this.data.appointmentDateTime).locale('es').format('h:mm a');
        this.data.r_fecha = moment(this.data.appointmentDateTime).locale('es').format('LL');
        this.preloader = false;
      }, (error: any) => {
      })
    } */
    this.agregarJsonQrDependiente();
  }

  agregarJsonQrDependiente(){
    if(this.data.parent==true){
      this.data.dataString = JSON.stringify(this.data.appointmentId);
      console.log(this.data)
    }
  }

  // OPEN MODAL LOGIN
  openAlert(data): void {
    const diallogRef = this.dialog.open(AlertCitasComponent, {
      data: {
        message: '¿DESEAS ELIMINAR TU CITA?',
        page: 'home',
        idCita: this.infoCita
      }
    });
    diallogRef.afterClosed().subscribe(result => {
    });


  }
  isUser() {
    if (this.auth.isUser() === 'user') {
      this.user = this.auth.User();
      this.show = true;
      return true
    } else {
      this.user = '';
      this.show = false;
      return false
    }
  }

  getpermissions() {
    const appointmentid = this.infoCita.appointmentId;
    const patientId = this.infoCita.patientId
    if (this.infoCita.parent === true) {
      //cambiar este data.patient.id por this.data.PatientId;
      this.permissionService.getAuthoParent(patientId, appointmentid).subscribe(async data => {
        console.log('data', data);
        if (data.token === "") {
          const data = JSON.stringify(this.data);
          /* this.router.navigate(['waiting']); */
          this.router.navigate(['waiting', data]);
          console.log('enviar a pagina de espera');
        }
        else if (data.token === 'finalizado') {
          /* const alert = await this.alertCtrl.create({
            header: 'Cita Terminada',
            subHeader: 'Esta cita ya ha finalizado',
            buttons: [
              {
                text: 'ok'
              }
            ]
          });
          await alert.present(); */
          console.log('cita rerminada levantar un modal');
          this.router.navigate(['home']);
        } else {
          const data = JSON.stringify(this.data);
          /* this.router.navigate(['video', data]) */
          this.router.navigate(['video', data])
        }
      })
    } else {
      this.permisions();
    }
  }

  permisions() {
    const appointmentId = this.infoCita.appointmentId;
    this.permissionService.getPermissionsVideo(appointmentId).subscribe(async (resul: any) => {
      console.log('data pedida desde my-date:', resul);
      if (resul.token === "") {
        const data = JSON.stringify(this.data);
        this.router.navigate(['waiting', data])
        console.log('enviar a pagina de espera');
      }
      else if (resul.token === 'finalizado') {
        /* const alert = await this.alertCtrl.create({
          header: 'Cita Terminada',
          subHeader: 'Esta cita ya ha finalizado',
          buttons: [
            {
              text: 'ok'
            }
          ]
        });
        await alert.present(); */
        console.log('levantar modal con cita terminada');
        this.router.navigate(['home']);
      } else {
        const data = JSON.stringify(this.data);
        this.router.navigate(['video', data])
      }
    })
  }

  openIndications() {
    this.dialog.open(NoCancelComponent);
  }


}
