import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { AuthService } from '../../auth/auth.service';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { PlanesProgramasService } from '../../../+planes-programas/planes-programas.service'

@Component({
  selector: 'app-modal-reserva-planes',
  templateUrl: './modal-reserva-planes.component.html',
  styleUrls: ['./modal-reserva-planes.component.sass']
})
export class ModalReservaPlanesComponent implements OnInit {
  public show : boolean = false;
  serviceError;
  public mailInvalid: boolean = false;
  public idplan : any;
  // NAME
  public name: string = '';
  public nameReadyValidate: boolean = false;
  public nameValidate: boolean;

  // LAST NAME
  public lastName: string = '';
  public lastNameReadyValidate: boolean = false;
  public lastNameValidate: boolean;

  // EMAIL
  public email: string = '';
  public emailReadyValidate: boolean = false;
  public emailValidate: boolean;

    // NUMBER DOCUMENT
    public phoneNumber: any = '';
    public phoneReadyValidate: boolean = false;
    public phoneValidate: boolean;

  public mayor: boolean = false;
  public mayus: boolean = false;
  public isNum: boolean = false
  public checked: any;
  public activateDocumentNumber = true;

  // VAR OF STATE
  public loaderSession: boolean = false;

  // ID CODE
  public idCode: any;

  public mode: string = 'indeterminate'
   // EXPRESIONS REGULAR
   public ER_NUM = /^([0-9])*$/;
   public ER_ONLY_NUM: any = /[0-9]/;
   public ER_STR: any = /^[A-Za-z\_\-\.\s\xF1\xD1]+$/;
   public ER_STR_MA: any = /[A-Z]/;
   public ER_EMA = /[\w-\.]{3,}@([\w-]{2,}\.)*([\w-]{2,}\.)[\w-]{2,4}/;

   // SAVE DATA DETAILS


  constructor( public dialogRed: MatDialogRef <ModalReservaPlanesComponent>, @Inject(MAT_DIALOG_DATA) public message: any, public planesReservas : PlanesProgramasService, public router : Router) { }

  ngOnInit() {
   
    if(this.message.page === 'aviva-cuida'){
      document.querySelectorAll('body')[0].classList.add('aviva-cuida-modal');
      document.querySelectorAll('body')[0].classList.remove('aviva-cura-modal');
      document.querySelectorAll('body')[0].classList.remove('aviva-home');
    }else if (this.message.page === 'home'){
      document.querySelectorAll('body')[0].classList.remove('aviva-cura-modal');
      document.querySelectorAll('body')[0].classList.remove('aviva-cuida-modal');
      document.querySelectorAll('body')[0].classList.add('aviva-home');
    }else if(this.message.page === 'aviva-cura'){
      document.querySelectorAll('body')[0].classList.add('aviva-cura-modal');
      document.querySelectorAll('body')[0].classList.remove('aviva-cuida-modal');
      document.querySelectorAll('body')[0].classList.remove('aviva-home');
    }
  }



// VALIDATOR NUMBER

// VALIDATOR STRING
onkeyValidateString(data:any): void{
  if(this.nameReadyValidate && data === this.name){
    if(data === this.name){
      this.blurValidateString(data);
    }
  }
  
  else if(this.emailReadyValidate && data === this.email){
    if(data === this.email){
      this.blurValidateString(data);
    }
  }
}

blurValidateString(data:any): void {
  
 if(data === this.name){
  this.nameReadyValidate = true;
  if( this.validateInput(this.ER_STR, data)){
    this.nameValidate = true;
  }else{
    this.nameValidate = false;
  }
 }
 
 else if(data === this.lastName){
   this.lastNameReadyValidate = true;
   if( this.validateInput(this.ER_STR, data)){
    this.lastNameValidate = true;
  }else{
    this.lastNameValidate = false;
  }
 }
 
 else if(data === this.phoneNumber){
  this.phoneReadyValidate = true;
  if( this.validateInput(this.ER_NUM, data) && data.length > 8){
   this.phoneValidate = true;
 }else{
   this.phoneValidate = false;
 }
}

else if(data === this.email){
  this.emailReadyValidate = true;
  if( this.validateInput(this.ER_EMA, data)){
   this.emailValidate = true;
 }else{
   this.emailValidate = false;
 }
}
  



}
// CLOSE MODAL REGISTER
onClickNo(){
this.dialogRed.close();
}

// VALIDATE INPUTS EXPRESIONES REGULARES
validateInput(expresion: any, input: string): boolean{
if(expresion.test(input)){
  return true;
}else{
  return false;
}
}


validateAllInputs(): boolean{
if(this.nameValidate && this.lastNameValidate &&  this.emailValidate && this.phoneValidate){
  return false;
}else{
  return true;
}
}

sendForm(){

  if(this.message.extra === ''){
    
    const id = this.message.idPlans
    const dataJson = {
      firstName: this.name,
      lastName: this.lastName,
      telephone: this.phoneNumber,
      email: this.email
    }
    let correo=this.email;
    let nombre=this.name;
    let apellido=this.lastName;
    let telefono=this.phoneNumber;
    if(id == '1' ){
    let emailreceptor='programavivamas@gmail.com';
   //let emailreceptor='dantequispeflores@gmail.com';
      this.idplan='Programa Viva+';
      this.planesReservas.sendcorreoplan(correo,nombre, apellido,telefono, this.idplan,emailreceptor).subscribe((data:any) => {
        console.log(data)
      })
    }else if (id == '2'){
      this.idplan='Programas de Embarazo';
     // let emailreceptor='dantequispeflores95@gmail.com';
     let emailreceptor='materno.mendiola@gmail.com';
      this.planesReservas.sendcorreoplan(correo,nombre, apellido,telefono, this.idplan,emailreceptor).subscribe((data:any) => {
        console.log(data)
      })
    }
   // let idplan=this.message.idPlans;
   
    this.planesReservas.savePlans(id , dataJson ).subscribe((data: any) => {
      console.log("primera ruta")
      console.log(id)
      console.log(dataJson)
       console.log(data);
       
        this.dialogRed.close();
        
   })
   
  }else {
 
    const url = this.message.extra;
    const url2=this.message.extra.slice(51,52);
    console.log("url",url)
    console.log("url2",url2)
    const dataJson = {
      firstName: this.name,
      lastName: this.lastName,
      telephone: this.phoneNumber,
      email: this.email
    }
    let correo=this.email;
    let nombre=this.name;
    let apellido=this.lastName;
    let telefono=this.phoneNumber;
    if(url2=='2'){
      this.idplan='Prog de Embarazo - Parto Natural - Programa Parto';
      let emailreceptor='materno.mendiola@gmail.com';
      console.log("plan",this.idplan)
      this.planesReservas.sendcorreoplan(correo,nombre, apellido,telefono, this.idplan,emailreceptor).subscribe((data:any) => {
        console.log(data)
      })
    }else if(url2=='3'){
      this.idplan='Prog de Embarazo - Parto Natural - Programa Superior';
      let emailreceptor='materno.mendiola@gmail.com';
      console.log("plan",this.idplan)
      this.planesReservas.sendcorreoplan(correo,nombre, apellido,telefono, this.idplan,emailreceptor).subscribe((data:any) => {
        console.log(data)
      })
    }else if(url2=='4'){
      this.idplan='Prog de Embarazo - Parto Natural - Programa Integral';
      let emailreceptor='materno.mendiola@gmail.com';
      console.log("plan",this.idplan)
      this.planesReservas.sendcorreoplan(correo,nombre, apellido,telefono, this.idplan,emailreceptor).subscribe((data:any) => {
        console.log(data)
      })
    }
    else if(url2=='5'){
      this.idplan='Prog de Embarazo - Ppor Cesárea - Programa Cesárea';
      let emailreceptor='materno.mendiola@gmail.com';
      console.log("plan",this.idplan)
      this.planesReservas.sendcorreoplan(correo,nombre, apellido,telefono, this.idplan,emailreceptor).subscribe((data:any) => {
        console.log(data)
      })
    }else if(url2=='6'){
      this.idplan='Prog de Embarazo - Ppor Cesárea - Programa Superior';
      let emailreceptor='materno.mendiola@gmail.com';
      console.log("plan",this.idplan)
      this.planesReservas.sendcorreoplan(correo,nombre, apellido,telefono, this.idplan,emailreceptor).subscribe((data:any) => {
        console.log(data)
      })
    }else if(url2=='7'){
      this.idplan='Prog de Embarazo - Ppor Cesárea - Programa Integral';
      let emailreceptor='materno.mendiola@gmail.com';
      console.log("plan",this.idplan)
      this.planesReservas.sendcorreoplan(correo,nombre, apellido,telefono, this.idplan,emailreceptor).subscribe((data:any) => {
        console.log(data)
      })
    }
    this.planesReservas.savePlansSpecific(url, dataJson).subscribe((data:any) => {
      console.log("segunda ruta")
      console.log(url)
      this.dialogRed.close();
    }, (error: any) => {
   
    })
  }


  this.show = true;

}




}
