import { Injectable } from '@angular/core';
import * as contentful from 'contentful';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { fromPromise } from 'rxjs/observable/fromPromise';
import 'rxjs/add/observable/fromPromise';
import 'rxjs/add/operator/map'

//var observableFromPromise = Observable.fromPromise(promiseSrc);
@Injectable({
  providedIn: 'root'
})
export class ContentfulService {
  public client = contentful.createClient({
    space:environment.contentful.spaceID,
    accessToken:environment.contentful.token
  })
  constructor() { }

  logContent(contentID){
    this.client.getEntry(contentID).then((entry)=>
    console.log(entry.fields)
    )
  }
  getContent(contentId){
    const promise = this.client.getEntry(contentId)
    return Observable.fromPromise(promise).map(entry => entry.fields)
  }
}