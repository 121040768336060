import { Injectable } from '@angular/core';
import { environment } from './../../environments/environment';
import { map } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class GetDataUserService {

  private url = environment.url;
  private urlDocument = this.url + 'api/v2/users/getPatientByDocument/';
  private reniec = 'https://apiperu.dev/api/dni/';

  constructor(public http: HttpClient) { }

  getTypesDocument(dni:string){
    return this.http.get(this.reniec + `${dni}`).pipe(
      map( res => {
        return res
      })
    )
}

getPublicKey(dni:string){
 // const auth_token = '30dcd655149906b1469ac3913125f30862b0ab1b4bc0425f8256166d98a82d02';
 // const auth_token = '1c356e6b5ee936dcc007522a831e9638b41bb74854621771e606dc8143326cbc';
 // token de compra anual 14/11/2022
  const auth_token = 'f04bee77a086a1145c500d00d94cb1140aef4d3536d9ff6c31ee99da51764b0d';
  const cabecera = new HttpHeaders({
    'Content-Type': 'application/json',
    'Authorization': `Bearer ${auth_token}`
  })
  return this.http.get(this.reniec + dni, {headers: cabecera})
  }
}
