import { Component, OnInit } from '@angular/core';
import { RecipesService } from '../recipes.service';
import { DependensService } from 'src/app/+video/dependens.service';


@Component({
  selector: 'app-mis-recetas',
  templateUrl: './mis-recetas.component.html',
  styleUrls: ['./mis-recetas.component.scss']
})
export class MisRecetasComponent implements OnInit {
  public recipesPrincipal;
  //public recipesTele;
  public consultasParent = [];

  constructor(public recipeSrv: RecipesService,
    public dependensSrv: DependensService) { }

  ngOnInit() {
    this.getRecipesPrincipal();
    //this.getRecipesTele();
    this.getDependens();

  }

  getRecipesPrincipal() {
    this.recipeSrv.getAllRecipes().subscribe(data => {
      this.recipesPrincipal = data;
      console.log('this.recipesPrincipal:', this.recipesPrincipal);
    })
  }
/* 
  getRecipesTele() {
    this.recipeSrv.getDatesConsulta().subscribe(data => {
      this.recipesTele = data;
      console.log('this.recipesTele:', this.recipesTele);
    })
  }
 */
  getDependens() {
    this.dependensSrv.getDependens().subscribe((data: any) => {
      console.log('data de los dependientes:', data);
      let patientsId = [];
      const id = data.forEach(element => {
        return patientsId.push(element.patientId)
      });
      console.log(patientsId);
      patientsId.forEach(e => {
        return this.getDatesParents(e)
      });
    })
  }

  getDatesParents(id) {
    this.recipeSrv.getDatesConsultaParent(id).subscribe(data => {
      data.forEach((element: any) => {
        return element.length > 0
      })
      this.consultasParent.push(data);
      console.log(this.consultasParent);
    })
  }

}