import { Component, OnInit } from '@angular/core';
import { fadeIn, SlideAnimation } from '../shared/animations/animation'
import { AuthService } from '../shared/auth/auth.service';
import { MatDialog, MatInkBar } from '@angular/material';
import { ModalComponent } from '../shared/modal/modal/modal.component';
/* import { GoogleTagManagerService } from 'angular-google-tag-manager'; */
import { ReservasService } from '../+reservas/reservas.service';
import { style } from '@angular/animations';
import { Router } from '@angular/router';
import {FormControl} from '@angular/forms';
import {map, startWith} from 'rxjs/operators';
import {Observable} from 'rxjs';
import { ContentfulService } from '../contentful.service';
import { CovidComponent } from '../shared/modal/covid/covid.component';
import { DeteccioncitaComponent } from '../shared/modal/deteccioncita/deteccioncita.component';
// import { BnNgIdleService } from 'bn-ng-idle';
export interface Doctor {
  fullname: string;
  id: string;
  idSpecialty: string;
  services: any;
  imageUrl: string;
}

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  animations:[SlideAnimation]
})
export class HomeComponent implements OnInit {
  defaultImage = 'data:image/jpeg;base64,/9j/4AAQSkZJRgABAQAAAQABAAD/2wCEAAEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAf/AABEIAAcACgMBEQACEQEDEQH/xAGiAAABBQEBAQEBAQAAAAAAAAAAAQIDBAUGBwgJCgsQAAIBAwMCBAMFBQQEAAABfQECAwAEEQUSITFBBhNRYQcicRQygZGhCCNCscEVUtHwJDNicoIJChYXGBkaJSYnKCkqNDU2Nzg5OkNERUZHSElKU1RVVldYWVpjZGVmZ2hpanN0dXZ3eHl6g4SFhoeIiYqSk5SVlpeYmZqio6Slpqeoqaqys7S1tre4ubrCw8TFxsfIycrS09TV1tfY2drh4uPk5ebn6Onq8fLz9PX29/j5+gEAAwEBAQEBAQEBAQAAAAAAAAECAwQFBgcICQoLEQACAQIEBAMEBwUEBAABAncAAQIDEQQFITEGEkFRB2FxEyIygQgUQpGhscEJIzNS8BVictEKFiQ04SXxFxgZGiYnKCkqNTY3ODk6Q0RFRkdISUpTVFVWV1hZWmNkZWZnaGlqc3R1dnd4eXqCg4SFhoeIiYqSk5SVlpeYmZqio6Slpqeoqaqys7S1tre4ubrCw8TFxsfIycrS09TV1tfY2dri4+Tl5ufo6ery8/T19vf4+fr/2gAMAwEAAhEDEQA/APqaP4a/twfCLwn4jn/ag8P/AA78YWum61H42tNE8N3vwn0DXtX8Y26+FpZNR1FND+FF/wCHrLVtP0/wo3hu28RaR4j03Vb7RtVWK8utmmaell8zKcKEVD3rxXJyJuMab+GM4cui5Xy2Vn8K1VrHtSnXxlWricT/ALRia9edeviKjVStiKtSTnUnXqVFz1Ks23z1JTc5OTcpSvdfE9t+0R8RJra3mh/Zy8E6TFLBFJFpVz470ie50yN41ZNPuJ7Dwh9gmnslItpZbL/RJJI2e2/clK8aecYKE5wlia/NCUoy9yb96LaevJrqtz9ZwXg3x3j8HhMdhcmy6eGxuFoYvDzlmeCpylQxNKFalKUHO8JSpzi3B6xbs9Uf/9k=';
  public user: string;
  public show: boolean;
  state: boolean = false;
  public page: any = 'home';



  public loaderSession: boolean = false;
  public buscador;
  serviceError;
  public doctors;
  public selectDoctor;
  public myControl = new FormControl();
  name: string;
  public urlBase;
  options: Doctor[] = [];
  filteredOptions: Observable<Doctor[]>;
  slidersImg:any[]=[
    { nombre:'Doctor',
      img:'/assets/img/slider/_home.jpg'
    },
    {
      nombre:'Rapipass',
      img:'/assets/img/slider/rapipass.png'
    }
  ]
  sliderContent:any[]=[
    { 
      id:1,
      title1:'SOMOS CLÍNICA',
      title2:' NO COVID',
      url:'/assets/img/slider/_home.png',
      href:null,
    },
    //SOLO PARA DESARROLLO, COMENTAR SI ES PARA PRODUCCIÓN
    /* { 
      id:3,
      title1:'medicinafetal_desktop',
      title2:null,
      url:'/assets/img/slider/medicinafetal_desktop.jpg',
      href:'https://www.aviva.pe/landings/medicina-fetal/',
    } */
  ]
  sliderContentMobile:any[]=[
    { 
      id:1,
      title1:null,
      title2:' NO COVID',
      url:'/assets/img/slider/home_mobile.jpg',
      href:null
    }
  ]
  mostrarSlide:boolean=true;
  public configHome = {
    direction: 'horizontal',
    slidesPerView: 1,
    centeredSlides: true,
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
    //rewind:true,
    effect:'fade',
    //rewind:true,
    pagination: {
      clickable: true,
      el: '.swiper-pagination-top',
      type: 'bullets',
      hideOnClick: false,
     /*  renderBullet: function (index, className) {
        return '<span class="' + className + '">' + (index + 1) + "</span>";
      }, */
    },
    //spaceBetween: 50,
    breakpoints: {
      700: {
        slidesPerView: 1
      }
    },
    autoplay:{
      delay:4000,
      disableOnInteraction:false,
    }
  }
  lesson$: Observable<any>;
  constructor(private AuthService : AuthService, 
              public dialog: MatDialog, 
              public reservaSrv: ReservasService, 
              public router: Router,
             /*  private gtmService: GoogleTagManagerService, */
              private contentful: ContentfulService,
             ){ 
                //  this.bnIdle.startWatching(10).subscribe((res) => {
                //   if(res) {
                //       console.log("session expired",res);
                //   }
                // })
              }

  ngOnInit() {
    this.llenarArraySlide();
    this.urlBase = this.AuthService.urlBaseAlter;
    this.getAllDoctors();
    this.AuthService._positionPage.next(this.page); 
    this.filteredOptions = this.myControl.valueChanges
    .pipe(
      startWith(''),
      map(value => typeof value === 'string' ? value : value.fullname),
        map(fullname => fullname ? this._filter(fullname) : this.options.slice())
        );
/*       console.log('my control:',this.myControl.valueChanges); */
        this.contentful.logContent('3pyEhmHMBJMz1bWpMfsGo2')
        this.lesson$ = this.contentful.getContent('3pyEhmHMBJMz1bWpMfsGo2')
        console.log(this.lesson$)
        console.log(this.contentful)
        //this.busquedaDoctores();
    //    this.openNotice();  //SOLO CUANDO SE REQUIERE MODAL
    //     this.openNotice();  //SOLO CUANDO SE REQUIERE MODAL prueba reserva cita
}

  llenarArraySlide(){
    this.contentful.client.getEntry('3pyEhmHMBJMz1bWpMfsGo2').then((entry:any)=>{
      entry.fields.slide.forEach(element => {
         this.sliderContent.push({
            url:element.fields.file.url,
            href:element.fields.description,
            title1:element.fields.title
          }); 
        console.log(element.fields)
      });
      //console.log(entry.fields.slideMobile.length)
      entry.fields.slideMobile.forEach(element => {
        console.log(entry.fields.slideMobile)
        //this.sliderContentMobile.push(element.fields.file);
        this.sliderContentMobile.push({
          url:element.fields.file.url,
          href:element.fields.description,
          title1:element.fields.title
        }); 
      });
      console.log(this.sliderContentMobile)
      this.mostrarSlide=false;

    })
  }
  displayFn(doctor: Doctor): string {
    return doctor && doctor.fullname ? doctor.fullname : '';
  }

  private _filter(fullname: String): Doctor[] {
    const filterValue = fullname.toLowerCase();
    return this.options.filter(option => option.fullname.toLowerCase().includes(filterValue));
  }
  

 /*  tag() {
    const gtmTag = {
      event: 'page',
      pageName: 's'
    };
    this.gtmService.pushTag(gtmTag);
    
  } */

  getAllDoctors(){
    this.reservaSrv.getAllDoctors().subscribe((data:any) => {
      this.options = data; 
      console.log(this.doctors);
    }, err => {
      console.log(err)
    })
  }
  
  changeState(){
    this.state = true;
  }

  isUser(){
    if(this.AuthService.isUser() === 'user'){
      this.user = this.AuthService.User();
      this.show = true;
      return true
    }else{
      this.user = '';
      this.show = false;
      return false
    }
  }

  logout(){
    if(this.AuthService.logout()){
      this.AuthService.getSesionPublic();
    
    }else{
    }
  }

    // OPEN MODAL LOGIN
    openLogin(): void{
    const diallogRef = this.dialog.open(ModalComponent, {
      data: 'home'
    });
    diallogRef.afterClosed().subscribe(res => {

    })
  }

  openModalManteince(){
  }

  onkeyValidateString(){

  }

  goToDoctor(item){
    console.log('ir a doctor:', item);
    const id =item.id;
    const especialtyId = item.services[0].id;
    console.log(id, especialtyId);
    this.router.navigateByUrl(`/especialidades-doctores/details-doctors/${especialtyId}/${id}`)
  }
  
  
//OPEN MODAL HOME
openNotice(): void{
  // const diallogRef = this.dialog.open(DeteccioncitaComponent, {
  //   data: 'home',
   
  // });
  const diallogRef = this.dialog.open(CovidComponent, {
    data: 'home'
  });
  diallogRef.afterClosed().subscribe(res => {

  })
}
}
