import { Injectable, ViewChild } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { AuthService } from '../shared/auth/auth.service';
import { MatAccordion } from '@angular/material/expansion';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';

@Injectable({
  providedIn: 'root'
})
export class RecipesService {
  @ViewChild(MatAccordion, { static: true }) accordion: MatAccordion;
  private consultas: AngularFirestoreCollection<any>

  constructor(public http: HttpClient,
    private authservice: AuthService,
    private afs: AngularFirestore) {
    this.consultas = afs.collection<any>('consultas');
  }

  urlBaseNodos = this.authservice.getUrlBaseNodos();
  urlBase = this.authservice.getUrlBase();


  getAllRecipes() {
    const session = JSON.parse(localStorage.getItem('session'));
    let headers = new HttpHeaders({ "Authorization": session.authorization });

    return this.http.get(this.urlBase + `api/v2/ebooking/getPrescripcionesEncuentro`, { headers }).pipe(
      map(data => {
        return data
      })
    )
  }

  getDatesDoctor(idDoctor){
    let id = idDoctor;
    console.log(id);
    return this.afs.collection('doctors').doc(id).valueChanges();
  }

 /*  getDatesConsulta() {
    const session = JSON.parse(localStorage.getItem('session'));
    let id = session.patientId;
    return this.afs.collection('consultas', ref => ref.where('uid', '==', id)).valueChanges();
  }
 */

  getDatesConsultaParent(id) {
    return this.afs.collection('consultas', ref => ref.where('uid', '==', id)).valueChanges();
  }

}
