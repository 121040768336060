import { Component, OnInit, Inject } from '@angular/core';
import { AuthService } from '../../auth/auth.service';
import { Router } from '@angular/router';
import { RegisterService } from './../../auth/+register/register.service';
import { ReservasService } from 'src/app/+reservas/reservas.service';

import * as moment from 'moment';
import { DependensService } from 'src/app/+video/dependens.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';


@Component({
  selector: 'app-deteccioncita',
  templateUrl: './deteccioncita.component.html',
  styleUrls: ['./deteccioncita.component.sass']
})
export class DeteccioncitaComponent implements OnInit {
  public detallecita: any;
  public detalleespecialidad: any;
  public iddelpaciente: any;
  public iddelacita:any;
  constructor(
    public reservaSrv: ReservasService,
    public dialogRed: MatDialogRef<DeteccioncitaComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private router: Router,
    public dialogRef: MatDialogRef<DeteccioncitaComponent>
  ) { }

  ngOnInit() {
    this.detallecita=this.data;
    this.detalleespecialidad=this.data[0].especialidad;
    console.log(this.detalleespecialidad);
    console.log(this.detallecita)
  }
  mantenercita(){
    this.router.navigate(['/']);
    this.dialogRef.close();
  }
  elminarcita(){
    this.iddelpaciente=this.data[0].patientId;
    this.iddelacita=this.data[0].appointmentId;
    let citaid=this.iddelacita;
    let pacienteid=this.iddelpaciente;
    this.reservaSrv.eliminacioncitarapida(citaid,pacienteid).subscribe((data:any)=>{
      console.log(data)
    })
    this.dialogRef.close();
  }

}
