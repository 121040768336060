import { Component, OnInit } from '@angular/core';
import { PermissionsVideoService } from '../permissions-video.service';
import { Router, ActivatedRoute } from '@angular/router';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-waiting',
  templateUrl: './waiting.component.html',
  styleUrls: ['./waiting.component.scss']
})
export class WaitingComponent implements OnInit {
  public dataDoctor;
  token: any;
  public resultado;
  public SERVERImage = environment.urlImagenes;

  constructor(public permissionSrv: PermissionsVideoService,
    private router: Router,
    private routes: ActivatedRoute) {
    const data = this.routes.snapshot.paramMap.get('data');
    this.dataDoctor = JSON.parse(data);
    console.log('thisdataDoctor:', this.dataDoctor);
  }

  ngOnInit() {
    this.verifyToken();
  }

  errorHandler(event) {
    event.target.src.style = "background-image:url(https://1.bp.blogspot.com/-p8EFlkXywyE/UDZvWTyr1bI/AAAAAAAAEU0/xL8pmKN1KOY/s1600/facebook.png); background-repeat: no-repeat; background-size:cover; height: 100vh;"
  }
  verifyToken() {
    this.resultado = setInterval(() => {
      this.getPermissionVideo();
    }, 30000);

    /*  if (this.token != '') {
       clearInterval(this.resultado);
     } */
  }

  getPermissionVideo() {
    let patientId = this.dataDoctor.patientId;
    let appoinmentid = this.dataDoctor.appointmentId;
    console.log(patientId, appoinmentid)
    if (this.dataDoctor.parent === true) {
      this.permissionSrv.getAuthoParent(patientId, appoinmentid).subscribe(async data => {
        this.token = data.token
        if (this.token === "") {
          console.log('aun no llega token');
        }
        else if (this.token === 'finalizado') {
          this.router.navigate(['home']);
          console.log('levantar un modal de finalización de cita')
          /* const alert = await this.alertCtrl.create({
            header: 'Cita Terminada',
            subHeader: 'Esta cita ya ha finalizado',
            buttons: [
              {
                text: 'ok'
              }
            ]
          });
          await alert.present(); */
          clearInterval(this.resultado);
        }
        else {
          /*  clearInterval(this.resultado); */
          const data = JSON.stringify(this.dataDoctor);
          this.router.navigate(['video', data]);
          clearInterval(this.resultado);
        }
        console.log(data);
      }, err => {
        console.log(err)
      })
    } else {
      this.permissionSrv.getPermissionsVideo(appoinmentid).subscribe(async data => {
        this.token = data.token
        if (this.token === "") {
          console.log('aun no llega token');
        }
        else if (this.token === 'finalizado') {
          this.router.navigate(['home']);
          /* const alert = await this.alertCtrl.create({
            header: 'Cita Terminada',
            subHeader: 'Esta cita ya ha finalizado',
            buttons: [
              {
                text: 'ok'
              }
            ]
          });
          await alert.present(); */
          console.log('cita finalizada');
          clearInterval(this.resultado);
        }
        else {
          /*  clearInterval(this.resultado); */
          const data = JSON.stringify(this.dataDoctor);
          this.router.navigate(['video', data]);
          clearInterval(this.resultado);
        }
        console.log(data);
      }, err => {
        console.log(err)
      })
    }
  }

  goToBack() {
    clearInterval(this.resultado);
    /* this.nav.back(); */
  }

  ngOnDestroy() {
    clearInterval(this.resultado);
  }
}
