import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-app-layout-register',
  templateUrl: './app-layout-register.component.html',
  styleUrls: ['./app-layout-register.component.sass']
})
export class AppLayoutRegisterComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
