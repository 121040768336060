import { Component, OnInit } from '@angular/core';
import { AuthService } from '../shared/auth/auth.service';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material';
import { NotificationComponent } from '../shared/modal/notification/notification.component';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-validate-code-password',
  templateUrl: './validate-code-password.component.html',
  styleUrls: ['./validate-code-password.component.sass']
})
export class ValidateCodePasswordComponent implements OnInit {

  public idRequest: any;
  public emailRecovery: any;
  public loaderSession: boolean = false;
  public serviceError: boolean = false;
  public mode: string = 'indeterminate';


  // EXPRESIONS REGULAR
  public ER_NUM = /^([0-9])*$/;
  public ER_ONLY_NUM: any = /[0-9]/;
  public ER_STR: any = /^[A-Za-z\_\-\\s\xF1\xD1]+$/;
  public ER_STR_MA: any = /[A-Z]/;
  public ER_EMA = /[\w-\.]{3,}@([\w-]{2,}\.)*([\w-]{2,}\.)[\w-]{2,4}/;

  // PASSWORD
  public password = '';
  public passwordReadyValidate: boolean = false;
  public passwordValidate: boolean = false;

  // PASSWORD REPEAT
  public passwordRepeat: any = '';
  public passwordRepeatReadyValidate: any;
  public passwordRepeatValidate: boolean;
  public activeRepeat: boolean = false;

  public mayor: boolean = false;
  public mayus: boolean = false;
  public isNum: boolean = false;


  public date1: string = '';
  public date2: string = '';
  public date3: string = '';
  public date4: string = '';

  public from: any;
  public emailHash;
  public dni;
  public documentType;
  public documentId;
  public selectdocument;
  constructor(private activateRoute: ActivatedRoute, private auth: AuthService, private router: Router, public dialog: MatDialog) { }

  ngOnInit() {
      this.dni = this.auth.dni;
      this.documentType = this.auth.documentType;
      console.log("document type", this.documentType)
    if( this.auth.emailHash){
      this.emailHash = this.auth.emailHash;
    }else{
      this.emailHash = " ";
      this.dni = this.auth.dni;
    }
    console.log(this.emailHash);
    this.activateRoute.params.subscribe(data => {
      this.from = data;
    })
    console.log(this.from)
    if (this.auth.idRecovery) {
      this.idRequest = this.auth.idRecovery;
      this.emailRecovery = this.auth.emailRecovery;
      console.log(this.idRequest, this.emailRecovery);
    } else {
      this.router.navigate(['/']);
    }

  }

  noPuntoComa(event) {
    var e = event || window.event;
    var key = e.keyCode || e.which;

    if (key === 110 || key === 190 || key === 188) {

      e.preventDefault();
    }
  }

  valida() {
    const a = <HTMLElement>document.querySelector('[tabindex="2"]');
    const b = <HTMLElement>document.querySelector('[tabindex="3"]');
    const c = <HTMLElement>document.querySelector('[tabindex="4"]');

    if (this.date1 != '') {
      a.focus();
    }
    if (this.date2 != '') {
      b.focus();
    }
    if (this.date3 != '') {
      c.focus();
    }
  }

  openNotification(): void {
    const diallogRef = this.dialog.open(NotificationComponent, {
      data: {
        from: 'general',
        tittle: 'TU CONTRASEÑA HA SIDO RECUPERADA',
        description: 'Vuelve a iniciar session para seguir tus operaciones',
        redirect: '/'
      },
      width: '60%',
    });
    diallogRef.afterClosed().subscribe(res => {
      console.log(res);
    })
  }

  activeRepeatPass() {

    if (this.passwordValidate === false) {
      this.activeRepeat = true;

    }
  }

  validatePass() {
    if (this.activeRepeat = true) {
      this.passwordRepeat = '';
      this.passwordRepeatValidate = false;
      this.validateLen();
      if (this.mayor) {
        this.passwordValidate = true;
        this.activeRepeat = false;
      } else {
        this.passwordValidate = false;
        this.activeRepeat = true;
      }
    }
  }

  validaNum() {
    if (this.validateInput(this.ER_ONLY_NUM, this.password)) {
      this.isNum = true;
    } else {
      this.isNum = false;
    }
  }

  validateMayus() {
    if (this.validateInput(this.ER_STR_MA, this.password)) {
      this.mayus = true;

    } else {
      this.mayus = false;
    }
  }

  validateLen() {
    if (this.password.length >= 8) {
      this.mayor = true;
    } else {
      this.mayor = false;
    }
  }

  passRepeatKey() {
    this.passwordRepeatReadyValidate = true;
    if (this.password === this.passwordRepeat) {

      this.passwordRepeatValidate = true;

    } else {

      this.passwordRepeatValidate = false;

    }

  }

  validateInput(expresion: any, input: string): boolean {
    if (expresion.test(input)) {
      return true;
    } else {
      return false;
    }
  }

  validateAllInputs(): boolean {
    if (this.passwordRepeatValidate && this.passwordValidate && this.date1.length && this.date2.length && this.date3.length && this.date4.length) {
      return false;
    } else {
      return true;
    }
  }


  sendCodeRecovery() {
    this.loaderSession = true;
    const dataCode = {
      code: this.date1 + this.date2 + this.date3 + this.date4,
      dni: this.dni,
    
    documentType: {
      id:this.documentType.id.toString(),
      name:this.documentType.name
    },
      id: this.idRequest,
      password: this.passwordRepeat
    }
    console.log(this.documentType)
    this.auth.sendCodeRecovery(dataCode).subscribe((data: any) => {

      if (data) {
        if (data.result === 'ok') {
          if (this.from.id === 'home') {
            console.log('es igual a home')
            this.router.navigate(['/']);
            this.openNotification();
          } else if (this.from.id === 'aviva-cuida') {
            console.log('es aviva-cuida')
            this.router.navigate(['/reservas/registro']);
            this.openNotification();
          }
          else if (this.from.id === 'aviva-cura') {
            console.log('es aviva-cura')
            this.router.navigateByUrl('/reservas/avivacura/registro');
            this.openNotification();
          }
          else if (this.from.id === 'reserva-doctor') {
            console.log('es reserva doctor')
            this.router.navigateByUrl('/especialidades-doctores/reserva-doctor');
            this.openNotification();
          }

        }
        console.log(data)
        this.loaderSession = false;
      }
    }, (error: any) => {
      if (error.error.status === 400) {

        this.passwordRepeatValidate = false;
        this.passwordValidate = false;
        this.loaderSession = false;
        this.serviceError = true;
        this.password = '';
        this.passwordRepeat = '';
        this.date1 = '';
        this.date2 = '';
        this.date3 = '';
        this.date4 = '';
        this.mayor = false;
        this.mayus = false;
        this.isNum = false;

      }
    })


  }
  sendRecover() {
    console.log(this.documentType)
    const dataSend = {
      documentNumber: this.dni,
      //  documentType:this.documentType
      documentType: {
        id:this.documentType.id.toString(),
        name:this.documentType.name
      },
    }
    console.log(dataSend)
    this.auth.validateEmail(dataSend).subscribe((data: any) => {
        console.log(data);
      console.log(data.result)
    })
  }

}
