import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { AuthService } from '../../auth/auth.service';
import { Router } from '@angular/router';
import { ReservasService } from 'src/app/+reservas/reservas.service';


@Component({
  selector: 'app-alert-citas',
  templateUrl: './alert-citas.component.html',
  styleUrls: ['./alert-citas.component.sass']
})

export class AlertCitasComponent implements OnInit {
  public loaderSession: boolean = false;
  public mode: string = 'indeterminate';
  public showNewInfo: boolean = false;
  public infoCita;

  constructor(public dialogRed: MatDialogRef<AlertCitasComponent>, @Inject(MAT_DIALOG_DATA)
  public message: any, public AuthService: AuthService, public router: Router,
    public reservaService: ReservasService) { }

  ngOnInit() {
    this.infoCita = this.reservaService.infoCita;

    if (this.message.page === 'aviva-cuida') {
      document.querySelectorAll('body')[0].classList.add('aviva-cuida-modal');
      document.querySelectorAll('body')[0].classList.remove('aviva-home');
      document.querySelectorAll('body')[0].classList.remove('aviva-cura-modal');
    }
    else if (this.message.page === 'aviva-cura') {
      document.querySelectorAll('body')[0].classList.add('aviva-cura-modal');
      document.querySelectorAll('body')[0].classList.remove('aviva-home');
      document.querySelectorAll('body')[0].classList.remove('aviva-cuida-modal');
    } else if (this.message.page === 'home') {
      document.querySelectorAll('body')[0].classList.remove('aviva-cura-modal');
      document.querySelectorAll('body')[0].classList.remove('aviva-cuida-modal');
      document.querySelectorAll('body')[0].classList.add('aviva-home');
    }
  }

  delete() {
    if (this.infoCita.parent === false) {
      this.AuthService.deleteCita(this.infoCita.appointmentId).subscribe((data: any) => {
        console.log(data)
        if (data === 'OK') {
          this.showNewInfo = true;
          this.dialogRed.close();
          this.router.navigate(['/mis-citas'])
          /* this.preloader = false; */
        } else {
          console.log('manejar el error aqui');
        }
      })
    } else {
      this.reservaService.destroyAppointmentContact(this.infoCita).subscribe((data: any) => {
        console.log(data);
        if (data === 'OK') {
          this.showNewInfo = true;
          this.dialogRed.close();
          this.router.navigate(['/mis-citas'])
          console.log('cita eliminada para familiar:');
        } else {
          console.log('manejar el error de eliminación para familiar')
        }
      })
    }
  }


  /*   delete(){
      this.loaderSession = true;
      this.AuthService.deleteCita(this.message.idCita).subscribe((data:any) => {
        if(data === 'OK'){
          console.log('cita eliminada')
  
          
          this.showNewInfo = true;
          this.dialogRed.close();
         this.router.navigate(['/mis-citas'])
      
          
          
        }else{
         
        }
      }) 
    }*/


  onClickNo() {
    this.dialogRed.close();
  }

}
