import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { AuthService } from '../../auth/auth.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-alert',
  templateUrl: './alert.component.html'
})
export class alertComponent implements OnInit {

  constructor(public dialogRed: MatDialogRef <alertComponent>, @Inject(MAT_DIALOG_DATA) public message: any, public AuthService : AuthService, public router : Router){ }

  ngOnInit() {

    if(this.message.page === 'aviva-cuida'){
      document.querySelectorAll('body')[0].classList.add('aviva-cuida-modal');
      document.querySelectorAll('body')[0].classList.remove('aviva-home');
      document.querySelectorAll('body')[0].classList.remove('aviva-cura-modal');
    }
    else if(this.message.page === 'aviva-cura'){
      document.querySelectorAll('body')[0].classList.add('aviva-cura-modal');
      document.querySelectorAll('body')[0].classList.remove('aviva-home');
      document.querySelectorAll('body')[0].classList.remove('aviva-cuida-modal');
    }else if (this.message.page === 'home' || this.message.page === 'reserva-doctor'){
      document.querySelectorAll('body')[0].classList.remove('aviva-cura-modal');
      document.querySelectorAll('body')[0].classList.remove('aviva-cuida-modal');
      document.querySelectorAll('body')[0].classList.add('aviva-home');
    }
  }
  onClickNo(){
    this.dialogRed.close('errorModal');
    if(this.message.page === 'aviva-cuida'){
      if(this.message.errorCode === 15001 || this.message.errorCode === 15002 || this.message.errorCode === 15003 || this.message.errorCode === 15004 ){
        console.log('el coigo es:' + this.message.errorCode)
        this.router.navigate(['/reservas']);
      }else if(this.message.errorCode === 5){
        console.log('el coigo es:' + this.message.errorCode)
        this.router.navigate(['/reservas']);
      }
      else{
        console.log('el coigo es:' + this.message.errorCode)
        this.router.navigate(['/reservas/avivacuida']);
      }
    }else if(this.message.page === 'aviva-cura'){
      if(this.message.errorCode === 15001 || this.message.errorCode === 15002 || this.message.errorCode === 15003 || this.message.errorCode === 15004 ){
        console.log('el coigo es:' + this.message.errorCode)
        this.router.navigate(['/reservas']);
      }else if(this.message.errorCode === 5){
        console.log('el coigo es:' + this.message.errorCode)
        this.router.navigate(['/reservas']);
      }else{
        console.log('el coigo es:' + this.message.errorCode)
        this.router.navigateByUrl('/reservas/avivacura');
      }
    }else if(this.message.page === 'reserva-doctor'){
      if(this.message.errorCode === 15001 || this.message.errorCode === 15002 || this.message.errorCode === 15003 || this.message.errorCode === 15004 ){
        console.log('el coigo es:' + this.message.errorCode)
        this.router.navigate(['/reservas']);
      }else if(this.message.errorCode === 5){
        console.log('el coigo es:' + this.message.errorCode)
        this.router.navigate(['/reservas']);
      }else{
        console.log('el coigo es:' + this.message.errorCode)
        this.router.navigateByUrl('/reservas');
      }
    }
  }
}
