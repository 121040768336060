// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
production: true,
  // Desarrollo http
  //  url: 'http://192.168.0.25/middleware2/',
  // urlImagenes: 'http://192.168.0.25/middleware2/img/professionals/',
  // urlBaseAlter: 'http://192.168.0.25/middleware2/',
  // registerMicro: 'http://192.168.0.25/micro-usu/api/v2/',
  // urlApiNodos: 'https://apiwsdev.aviva.pe/',
  // apiKeyCulqi: 'pk_test_e85SD7RVrWlW0u7z',
  // agora: {
  //   appId: 'b75a830a11a84a0bbb812fc81aa60860'
  // }, 
  // Nuevo desaroollo
   url: 'https://192.168.2.25/middleware2/',
  urlImagenes: 'https://192.168.2.25/middleware2/img/professionals/',
  urlBaseAlter: 'https://192.168.2.25/middleware2/',
  registerMicro: 'https://192.168.2.25/micro-usu/api/v2/',
  urlApiNodos: 'https://apiwsdev.aviva.pe/',
  apiKeyCulqi: 'pk_test_e85SD7RVrWlW0u7z',
  agora: {
    appId: 'b75a830a11a84a0bbb812fc81aa60860'
  }, 
   // Nuevo desaroollo
  //  url: 'https://devapache2.aviva.pe/middleware2/',
  // urlImagenes: 'https://devapache2.aviva.pe/middleware2/img/professionals/',
  // urlBaseAlter: 'https://devapache2.aviva.pe/middleware2/',
  // registerMicro: 'https://devapache2.aviva.pe/micro-usu/api/v2/',
  // urlApiNodos: 'https://apiwsdev.aviva.pe/',
  // apiKeyCulqi: 'pk_test_e85SD7RVrWlW0u7z',
  // agora: {
  //   appId: 'b75a830a11a84a0bbb812fc81aa60860'
  // }, 

  firebaseConfig: {
    apiKey: "AIzaSyDGI7Pv0_1CePDRcL8Lp-CDO9Qh_ATyeZc",
    authDomain: "doctordata-2d06b.firebaseapp.com",
    projectId: "doctordata-2d06b",
    storageBucket: "doctordata-2d06b.appspot.com",
    messagingSenderId: "661404189322",
    appId: "1:661404189322:web:6c0bbf6ef30fd42cf4a373",
    measurementId: "G-XQFR7J8EB9"
},
/* googleAnalyticsId: 'UA-140961400-1prueba', */
  // url: 'https://api.aviva.pe/middleware2/',
  // urlImagenes: 'https://api.aviva.pe/middleware2/img/professionals/',
  // registerMicro: 'https://api.aviva.pe/micro-usu/api/v2/',
  // urlBaseAlter: 'https://api.aviva.pe/middleware2/',
  // urlApiNodos: 'https://apiws.aviva.pe/',
  // apiKeyCulqi: 'pk_live_CyArY9ygzb0d7oZb',
  // agora: {
  //    appId: 'b75a830a11a84a0bbb812fc81aa60860'
  //  },
   googleAnalyticsId: 'UA-140961400-1', 
   //CONTENTFUL
   contentful:{
     spaceID: 'g18q1i5cgg5q',
     token:'6H2as534zm1nYtRN9NjUYKOXlolclBun1VaZblKyOg8'
   }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.