import { Component, OnInit, Inject } from '@angular/core';
import { DependensService } from 'src/app/+video/dependens.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { ReservasService } from 'src/app/+reservas/reservas.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-delete-parent',
  templateUrl: './delete-parent.component.html',
  styleUrls: ['./delete-parent.component.sass']
})
export class DeleteParentComponent implements OnInit {
  public dependens;
  public showNewInfo: boolean = false;

  constructor(public dependentsServ: DependensService,
    public dialogRed: MatDialogRef<DeleteParentComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public reservaSrv: ReservasService,
    public router: Router) { }

  ngOnInit() {
  }

  deleteParent() {
    let id = this.reservaSrv.idParent;
    this.dependentsServ.deleteDepend(id).subscribe(data => {
      console.log(data);
      this.dependentsServ.getDependens().subscribe(data => {
        this.dialogRed.close({ data: data });
        this.showNewInfo = true;
      })
    }, err => {
      console.log(err)
    })
  }

  getAlldependends() {
    this.dependentsServ.getDependens().subscribe(data => {
      this.dependentsServ.dependens = data;
    })
  }

  cancelModal() {
    this.dialogRed.close();
    console.log('cerrar modal');
  }

  onClickNo() {
    this.dialogRed.close();
  }

}
