import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { AuthService } from '../../auth/auth.service';
import { PlanesProgramasService } from '../../../+planes-programas/planes-programas.service';
import { Router, NavigationEnd } from '@angular/router';
import {EspecialidadesDoctoresService} from '../../../+especialidades-y-doctores/especialidades-doctores.service';

@Component({
  selector: 'app-app-layout-planes-programas',
  templateUrl: './app-layout-planes-programas.component.html',
  styleUrls: ['./app-layout-planes-programas.component.sass']
})
export class AppLayoutPlanesProgramasComponent implements OnInit {

  public activateNav;
  public listNav
  public showModalMenu : boolean;
  public listNav2
  index:number=1;
  public listNavSpecialidades;
  public selectUrl;
  activateNav2;
  page;
  public programa: string = 'Seleccionar otro programa'

  public programa2: string = 'Seleccionar otra especialidad'
  static normalizeQueryParams: (params: string) => string
  constructor(private especialService: EspecialidadesDoctoresService, private router : Router, private planesService: PlanesProgramasService, private auth: AuthService,  private cdRef: ChangeDetectorRef,) { }

  ngOnInit() {
    this.showModalMenu = false;
    this.auth.stateNav.subscribe(data => {
      this.activateNav = data;
      if(this.activateNav){
   
        this.getPlans();
      }
    })

    this.auth.stateNav2.subscribe(data => {
      this.activateNav2 = data.state;
      this.page = data.page
        if(this.activateNav2){
        this.getPlans2();
      }
    })


 

    if (!this.cdRef['destroyed']) {
      this.cdRef.detectChanges();
      
    }

    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
          window.scrollTo(0, 0);
      }
    });
  }

  getPlans(){
    this.planesService.getPlans().subscribe((data: any) => {
      console.log(data.data);
      //quitando planes de control niño sano y chequeo
      const filtrados = data.data.filter(x => x.id == "1" || x.id == "2");
        this.listNav = filtrados;
        console.log(filtrados);
 
    })
  }

  getPlans2(){
    let url=document.location.href;
    let indexEspecialidad = url.split("/")[5];
    this.especialService.getSpecialty().subscribe((data: any) => {
      console.log(data.data);
      //quitando planes de control niño sano y chequeo
      const filtrados = data.data.filter(x => x.id != indexEspecialidad);
        this.listNav2 = filtrados;
        console.log(url.split("/")[5])
        console.log(document.location.href);
    })
  }

  setUrl(event){
    const data = event.target.id;
    console.log(event)
  
  }

  selecGender(event){
    this.selectUrl = event.target.value;
    this.router.navigate(['/planes-programas', this.selectUrl]);
  }

  selecGender2(event){

   
    this.selectUrl = event.target.value;
    this.router.navigateByUrl('/especialidades-doctores/especialidades-details/' + this.selectUrl)
    
  }

  showModalMenuList(){
    this.showModalMenu = !this.showModalMenu;
  }
  reload(){
    location.reload();
  }
  goEspecialista(item1,item2){
    this.router.navigateByUrl('/especialidades-doctores/especialidades-details/' + item1+'/'+item2)
  }
}
