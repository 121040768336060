import { Component, OnInit, Inject } from '@angular/core';
import { AuthService } from '../shared/auth/auth.service';
import * as moment from 'moment';
import { ReservasService } from '../+reservas/reservas.service';
import { Router } from '@angular/router';


@Component({
  selector: 'app-mis-citas',
  templateUrl: './mis-citas.component.html'
})
export class MisCitasComponent implements OnInit {
  orderInfo = {};
  orderLetrasMain;
  dataa;
  date;
  user;
  show;
  urlBase;
  public preloader: any;
  public citasDependientes: any;

  public color: any = 'accent';
  public mode: any = 'indeterminate';
  constructor(public auth: AuthService,
    public router: Router,
    public reservaService: ReservasService) { }

  ngOnInit() {
    this.urlBase = this.auth.urlBaseAlter;
    this.preloader = true;
    this.auth.getCitasUser().subscribe((data: any) => {
      console.log('citas pendientes', data);
      data.forEach(element => {
        element.hour = moment(element.appointmentDateTime).locale('es').format('h:mm a');
        element.appointmentDateTime = moment(element.appointmentDateTime).locale('es').format('LL');
        element.parent = false;
        element.data = {
          /*nombre:data.patientName,
           surname1: element.patientLastName1,
          surname2: element.patientLastName2, */
          /* basicService:element.basicServiceId, */
         /*  especialty:element.basicServiceDescription, */
          numberEncounter: element.appointmentId,
         /*  hour:element.hour, */
         /*  planDescription: element.planDescription, */
          /* serviceDescription: element.serviceDescription, */
          /* appointmentDateTime: element.appointmentDateTime, */
        }
        if(element.data){
          element.dataString = JSON.stringify(element.appointmentId);
        }
        this.date = data.filter((elements: any) => elements.appointmentDateTime === element.appointmentDateTime);
        this.orderInfo[element.appointmentDateTime] = []
        this.orderInfo[element.appointmentDateTime].push(this.date)
        
        
      });
      this.orderLetrasMain = Object.keys(this.orderInfo).sort();
      console.log('this.orderLetrasMain:', this.orderLetrasMain);

      this.preloader = false;

    })
    this.getCitasPacienteContacto();
    
  }

  getCitasPacienteContacto() {
    this.auth.getdependesDay().subscribe((data: any) => {
      /*     console.log('data de familiares:', data); */
      const citasDependientes = data.filter(x => {
        return x.appointments.length > 0
      });
      data.map(x => {
        x.hour = moment(x.appointmentDateTime).locale('es').format('h:mm a')
      })
      citasDependientes.map(x => {
        x.parent = true;
      })
      console.log('datosFiltrados:', citasDependientes);
      this.citasDependientes = citasDependientes;
      console.log('this.citasDependientes:', this.citasDependientes);
    })
  }

  goToCitaDetail(cita) {
    cita.parent = false;
    this.reservaService.infoCita = cita;
    console.log('cita:', cita);
    this.router.navigate([`/mis-citas/detalles/${cita.appointmentId}`])
  }

  goToCitaDetailParent(cita) {
    cita.parent = true;
    this.reservaService.infoCita = cita;
    console.log('cita:', cita);
    this.router.navigate([`/mis-citas/detalles/${cita.appointmentId}`])
  }

  isUser() {
    if (this.auth.isUser() === 'user') {
      this.user = this.auth.User();
      this.show = true;
      return true
    } else {
      this.user = '';
      this.show = false;
      return false
    }
  }
}
