import { Component, OnInit, OnDestroy, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { AuthService } from '../../auth/auth.service';
import { Router } from '@angular/router';


@Component({
  selector: 'app-modal-planes-programas',
  templateUrl: './modal-planes-programas.component.html'
})
export class ModalPlanesProgramasComponent implements OnInit, OnDestroy {

  public loaderSession: boolean = false;
  public mode: string = 'indeterminate';
  public showNewInfo: boolean = false;
  public change: boolean = false;
  public vacio = '';


  public dataDetails = '';

  constructor(public dialogRed: MatDialogRef <ModalPlanesProgramasComponent>, @Inject(MAT_DIALOG_DATA) public message: any, public AuthService : AuthService, public router : Router) { }

  ngOnInit() {
    if(this.message.show){
      this.dataDetails = this.message.dataPrograms;

      console.log(this.message)
    
    }
    if(this.message.page === 'aviva-cuida'){
      document.querySelectorAll('body')[0].classList.add('aviva-cuida-modal');
      document.querySelectorAll('body')[0].classList.remove('aviva-home');
      document.querySelectorAll('body')[0].classList.remove('aviva-cura-modal');
    }
    else if(this.message.page === 'aviva-cura'){
      document.querySelectorAll('body')[0].classList.add('aviva-cura-modal');
      document.querySelectorAll('body')[0].classList.remove('aviva-home');
      document.querySelectorAll('body')[0].classList.remove('aviva-cuida-modal');
    }else if (this.message.page === 'home'){
      document.querySelectorAll('body')[0].classList.remove('aviva-cura-modal');
      document.querySelectorAll('body')[0].classList.remove('aviva-cuida-modal');
      document.querySelectorAll('body')[0].classList.add('aviva-home', 'page-embarazo');
    }
  }

  ngOnDestroy(){
    document.querySelectorAll('body')[0].classList.remove('aviva-home', 'page-embarazo');
  }


  onClickNo(){
    this.dialogRed.close(this.vacio);
  }

  changeState(){
    this.change = true;
  }

  saveProgramsSpecifi(data){
    this.dialogRed.close(data);
  }

}
