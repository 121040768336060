import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material';
import { Router } from '@angular/router';


@Component({
  selector: 'app-status',
  templateUrl: './status.component.html',
  styleUrls: ['./status.component.scss']
})
export class StatusComponent implements OnInit {

  constructor(public dialogRed: MatDialogRef<StatusComponent>,
              public router: Router) { }

  ngOnInit() {
  }

  onClickNo() {
    this.dialogRed.close();
    this.router.navigate(['/'])
  }

}
