import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../shared/auth/auth.service';
import {fadeIn} from '../../shared/animations/animation'
import { BreakpointObserver } from '@angular/cdk/layout';

@Component({
  selector: 'app-conoce-aviva',
  templateUrl: './conoce-aviva.component.html',
  animations: [fadeIn]
})

export class ConoceAvivaComponent implements OnInit {

  lat: number = -11.956256;
  lng: number = -77.069612;
  mapType = 'roadmap';
  zoom = 15;

  public config1 = {
    direction: 'horizontal',
    slidesPerView: 3,
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
    pagination: {
      clickable: true,
      el: '.swiper-pagination-top',
      type: 'bullets',
      hideOnClick: false
    },
    spaceBetween: 50,
    breakpoints: {

      // when window width is <= 640px
      768: {
        slidesPerView: 2,
        spaceBetween: 5,
      }
    }
  }

  public configgg = {
    direction: 'horizontal',
    slidesPerView: 3,
    navigation: {
      nextEl: '.swiper-button-next-2',
      prevEl: '.swiper-button-prev-2',
    },
    pagination: {
      clickable: true,
      el: '.swiper-pagination-button',
      type: 'bullets',
      hideOnClick: false
    },
    spaceBetween: 50,
    breakpoints: {

      768: {
        slidesPerView: 2,
        spaceBetween: 10,

      }
    }
  }
  IMAGES = {
    ONE: {
      with: '336',
      height: '401'
    },
    TWO: {
      with: '336',
      height: '401'
    },
  }


  public color: any = 'accent';
  public mode: any = 'indeterminate';
  public sliderAvivaOfrece: any;
  public iconsService: any;
  public sliderInsta: any;
  public preloader: boolean;
  isDesktop: boolean;
  constructor(private auth: AuthService,  private breakpointObserver: BreakpointObserver) { }

  ngOnInit() {

    this.breakpointObserver
      .observe(['(min-width: 1200px)'])
      .subscribe((result) => this.responsive(result.matches));
  
    this.preloader = true;
    this.auth.getAboutAviva().subscribe((data: any) => {
      this.sliderAvivaOfrece = data.features;
      this.iconsService = data.services;
      this.sliderInsta = data.facilities;
      this.preloader = false;
    })
  }

  responsive(matches: boolean) {

    this.IMAGES.ONE = {
      with: matches ? '336' : '170',
      height: matches ? '401' : '201',
    }

    this.IMAGES.TWO = {
      with: matches ? '336' : '167',
      height: matches ? '401' : '199',
    }

  }

}
