import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Router } from '@angular/router';
import { AuthService } from '../../auth/auth.service';
import { RegisterService } from './../../auth/+register/register.service';


@Component({
  selector: 'app-recovery',
  templateUrl: './recovery.component.html',
  styleUrls: ['./recovery.component.scss']
})
export class RecoveryComponent implements OnInit {

  public loaderSession: boolean = false;
  public app = 'ebooking';
  public email: string = '';
  public emailValidate: boolean;
  public validate: boolean = false;
  public readyValidate: boolean = false;
  public serviceError: boolean;
  public passValidate: boolean;
  public validatePasss: boolean;
  public validateDni: boolean = false;
  public readydniValidate: boolean = false;

  public document: any = 'Tipo de documento';
  public selectdocument: string = "D.N.I";
  public documentId = '1';
  public tipeDocumentService;
  public hideBox: boolean = false;

  // NUMBER DOCUMENT
  public documentNumber: any = '';
  public documentReadyValidate: boolean = false;
  public documentValidate: boolean;

  public registerForm;
  public digitoVa;
  public activateDocumentNumber: boolean=false;
  public documentInvalid: boolean = false;

  public dni: string = '';
  public dniValidate: boolean;

  public ER_NUM = /^([0-9])*$/;
  public ER_STR: any = /^[A-Za-z\_\-\.\s\xF1\xD1]+$/;
  public ER_STR_MA: any = /[A-Z]/;
  public ER_EMA = /[\w-\.]{3,}@([\w-]{2,}\.)*([\w-]{2,}\.)[\w-]{2,4}/;

  constructor(public dialogRed: MatDialogRef<RecoveryComponent>,
    public router: Router,
    public RegisterService: RegisterService,
    @Inject(MAT_DIALOG_DATA) public message: string,
    public AuthService: AuthService) { }

  ngOnInit() {


    this.message = 'home'
    document.querySelectorAll('body')[0].classList.remove('aviva-cura-modal');
    document.querySelectorAll('body')[0].classList.remove('aviva-cuida-modal');
    document.querySelectorAll('body')[0].classList.add('aviva-home');

    this.RegisterService.userDocuments()
    .subscribe(data => {
      this.tipeDocumentService = data;
    }, error => {
    })
  }

  onClickNo() {
    this.dialogRed.close();
  }

  // VALIDATE EMAIL KEYUP && BLUR
  onkeyBlurEmail() {
    if (this.email.length > 1) {
      this.readyValidate = true;
      this.onkeyEmail();
    }
  }
  onkeyEmail() {
    this.serviceError = false;
    if (this.readyValidate) {
      if (this.validateInput(this.ER_EMA, this.email)) {
        this.emailValidate = true;
      } else {
        this.emailValidate = false;
      }
    }
  }

  // VALIDATE SIMPLE
  validateInputSim(input: any) {
    if (input.length > 3) {
      this.validatePasss = true;
      this.passValidate = true;
    } else {
      this.passValidate = false;
    }
  }

  // VALIDATE INPUTS EXPRESIONES REGULARES
  validateInput(expresion: any, input: string): boolean {
    this.validate = true;
    if (expresion.test(input)) {
      return true;
    } else {
      this.emailValidate = false;
      return false;
    }
  }

  onkeyBlurDni() {
    if (this.dni.length > 7) {
      this.validateDni = true;
      this.onkeyDni();
    }
  }

  onkeyDni() {
    this.serviceError = false;
    if (this.validateDni) {
      this.dniValidate = true;
     /*  if (this.validateInput(this.ER_NUM, this.dni)) {
      } else {
      } */
    }else{
      this.dniValidate = false;
    }
  }

  sendRecover() {
    this.loaderSession = true;
    this.loaderSession = true;
    const dataSend = {
      documentNumber: this.dni,
      documentType: {
        id:this.documentId.toString(),
        name:this.selectdocument
      }
    }
    console.log(dataSend)
    this.AuthService.validateEmail(dataSend).subscribe((data: any) => {
        console.log(data);
      if (data.result === 'ok') {
        this.loaderSession = false;
        this.AuthService.idRecovery = data.id
        this.AuthService.emailRecovery = this.email
        this.AuthService.emailHash = data.emailHash;
        this.AuthService.dni = this.dni;
        const documentType = {
          id:this.documentId,
          name:this.selectdocument
        }
        this.AuthService.documentType = documentType;
        console.log('mensaje en recovery:', this.message);
        this.router.navigate(['/recuperar-contraseña', this.message]);
        this.onClickNo();
      }
      console.log(data.result)
    }, (error: any) => {
      if (error.error.result === 'error') {
        this.email = '';
        this.emailValidate = false;
        this.serviceError = true;
        this.loaderSession = false;
      }
      console.log(error.error.result)
    })
    // this.router.navigate(['/recuperar-contraseña']);
    // this. onClickNo();
    console.log(this.email)
  }

  selectDocument(event) {
    const documentType = event.target.selectedOptions[0].textContent;
    if (documentType === 'No Tiene') {
      this.hideBox = true;
    } else if(documentType === 'D.N.I'){
      this.registerForm = false;
      this.digitoVa = true;
        this.hideBox = false;
        this.documentNumber = '';
        this.selectdocument = event.target.selectedOptions[0].textContent;
        this.activateDocumentNumber = false;
        this.documentId = event.target.value;
    }else if(documentType === 'C.E.'){
      this.registerForm = true;
      this.digitoVa = false;
      this.hideBox = false;
      this.documentNumber = '';
      this.selectdocument = event.target.selectedOptions[0].textContent;
      this.activateDocumentNumber = false;
      this.documentId = '3';
      console.log(this.documentId);
    }else if(documentType === 'Pasaporte.'){
      this.registerForm = true;
      this.digitoVa = false;
      this.hideBox = false;
      this.documentNumber = '';
      this.selectdocument = event.target.selectedOptions[0].textContent;
      this.activateDocumentNumber = false;
      this.documentId = '2';
      console.log(this.documentId);
    }else{
      this.registerForm = true;
      this.digitoVa = false;
      this.hideBox = false;
      this.documentNumber = '';
      this.selectdocument = event.target.selectedOptions[0].textContent;
      this.activateDocumentNumber = false;
      this.documentId = event.target.value;
    }
  }

    // VALIDATOR STRING
    onkeyValidateString(data: any): void {
 
      if (this.documentReadyValidate && data === this.documentNumber) {
        if (data === this.documentNumber) {
          this.blurValidateString(data);
        }
      }
    }


blurValidateString(data: any): void {
    if (data === this.documentNumber) {
      this.documentReadyValidate = true;
      if (this.validateInput(this.ER_NUM, data)) {
        this.documentValidate = true;
      } else {
        this.documentValidate = false;
      }
    }
  }

  soloNumeros(e){
    let tecla = (document.all)?e.keyCode:e.which;
    if(tecla==8){
      return true;
    }
    let patron  = /[0-9]/;
    let tecla_final = String.fromCharCode(tecla);
    return patron.test(tecla_final);
  }
}
