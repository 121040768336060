import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material';

@Component({
  selector: 'app-register-ok',
  templateUrl: './register-ok.component.html',
  styleUrls: ['./register-ok.component.scss']
})
export class RegisterOkComponent implements OnInit {

  constructor(public dialogRed: MatDialogRef<RegisterOkComponent>) { }

  ngOnInit() {
  }


  onClickNo() {
    this.dialogRed.close();
  }

}
