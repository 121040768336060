import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { AuthService } from '../shared/auth/auth.service';
import { environment } from 'src/environments/environment';



@Injectable({
  providedIn: 'root'
})
export class PermissionsVideoService {

  private SERVER = environment.urlBaseAlter;
  private url = `${this.SERVER}api/v2/ebooking/token-paciente?appointmentid=`;
  private urlAutho = `${this.SERVER}api/v2/ebooking/token-paciente-contacto?patientid=`;

  constructor(private http: HttpClient) { }

  getPermissionsVideo(appointmentid) {
    const session = JSON.parse(localStorage.getItem('session'));
    let headers = new HttpHeaders({ "Authorization": session.authorization });

    return this.http.get(this.url + appointmentid, { headers }).pipe(
      map((resp: any) => {
        return resp
      })/* .catch(e =>{
                console.log('error desde el servidor:',e);
              }) */
    )
  }

  getPermissionsVideoParent(appointmentid) {
    const session = JSON.parse(localStorage.getItem('session'));
    let headers = new HttpHeaders({ "Authorization": session.authorization });
    return this.http.get(this.url + appointmentid, { headers }).pipe(
      map((resp: any) => {
        return resp
      })/* .catch(e =>{
                console.log('error desde el servidor:',e);
              }) */
    )
  }

  getAuthoParent(patientId, appointmentid) {
    const session = JSON.parse(localStorage.getItem('session'));
    let headers = new HttpHeaders({ "Authorization": session.authorization });

    return this.http.get(this.urlAutho + `${patientId}&appointmentid=${appointmentid}`, { headers }).pipe(
      map((resp: any) => {
        return resp
      })
    )
  }



}
