import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpRequest } from '@angular/common/http';
import { AuthService } from '../shared/auth/auth.service';


@Injectable({
  providedIn: 'root'
})
export class PlanesProgramasService {


  constructor(public http: HttpClient, private auth: AuthService) { }
  public urlsolicitud = this.auth.urlsolicitudplan;
  public urlBase = this.auth.urlApiNodos;

  getPlans() {
    return this.http
      .get(this.urlBase + 'plans');
  }

  sendcorreoplan(correo: any,nombre: any, apellido: any,telefono: any, idplan: any,emailreceptor: any ){
    return this.http.post(this.urlsolicitud ,{
      email: correo,
      firstName: nombre,
      lastName: apellido,
      telephone: telefono,
      plan: idplan,
      emailTo: emailreceptor
    }).pipe(
      ((resp:any) =>{
        return resp
      })
    )
  }

  getData(data){
    return this.http
     .get(this.urlBase  + 'plans/' + data);
  }

  getplanVivaMas(dataplan){
    return this.http
     .get(this.urlBase  + 'plans/' + dataplan);
  }

  getProgramasEmbarazo(){
    return this.http
     .get(this.urlBase  + 'plans/programas-de-embarazo');
  }

  getNiñoSano(){
    return this.http
     .get(this.urlBase  + 'plans/programa-de-nino-sano');
  }

  getChequeoPReventivo(){
    return this.http
     .get(this.urlBase  + 'plans/chequeo-preventivo');
  }
  startReser(data){
    return this.http
     .post(this.urlBase + 'plans/reserve', data);
  }

  //SEND PLANS

  savePlans(data, info){
    return this.http
      .post(this.urlBase + 'plans/'+ data +'/reserve', info)
  }

  savePlansSpecific(data: any, info: any){
    const url = data
    return this.http
      .post(url, info )
  }
}
