import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-app-layout-internas',
  templateUrl: './app-layout-internas.component.html',
  styleUrls: ['./app-layout-internas.component.sass']
})
export class AppLayoutInternasComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
