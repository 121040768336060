import { Component, OnInit } from '@angular/core';
import {  AuthService } from '../shared/auth/auth.service';
import * as moment from 'moment';

// var moment = require('moment-timezone');

@Component({
  selector: 'app-mis-datos',
  templateUrl: './mis-datos.component.html',
})
export class MisDatosComponent implements OnInit {

  public color: any = 'accent';
  public mode: any = 'indeterminate';
  public preloader:any = false;

  public data = {
    apellido1: "",
apellido2: "",
bloqueado: 0,
fecha_nac: "",
nombre: "",
patientId: '',
sexo: "",
telefono1: ""
  };

  user;
  show;

  constructor(private auth: AuthService) { }

  ngOnInit() {
    this.preloader = true;

    this.isUser();
    
    this.auth.getDatosUsers().subscribe((dataa:any) => {
      this.data = dataa;
      this.data.fecha_nac = moment.utc(this.data.fecha_nac).format('DD-MM-YYYY');
      this.preloader = false;
    })
  }

  isUser(){
    if(this.auth.isUser() === 'user'){
      this.user = this.auth.User();
      this.show = true;
      return true
    }else{
      this.user = '';
      this.show = false;
      return false
    }
  }

}
