import { NgModule, Component } from '@angular/core';
import { ModuleWithProviders } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './+home/home.component';

//ERRORS COMPONENT
import { Errors404Component } from './+errors/404/errors404.component';
import { Errors500Component } from './+errors/500/errors500.component';



//IMPORT LAYOUTS
import { AppMainLayoutComponent } from './shared/layouts/app-main-layout/app-main-layout.component';
import { AppLayoutReservasComponent } from './shared/layouts/app-layout-reservas/app-layout-reservas.component';
import { AppLayoutRegisterComponent } from './shared/layouts/app-layout-register/app-layout-register.component';
import { ReservaFinalizadaComponent } from './+reserva-finalzada/reserva-finalizada/reserva-finalizada.component';
import { AppLayoutInternasComponent } from './shared/layouts/app-layout-internas/app-layout-internas.component';
import { AppLayoutErrorsComponent } from './shared/layouts/app-layout-errors/app-layout-errors.component';
import { AppLayoutPlanesProgramasComponent } from './shared/layouts/app-layout-planes-programas/app-layout-planes-programas.component';
import { ValidateCodeComponent } from './validate-code/validate-code.component';
import { ConoceAvivaComponent } from './+conoce-aviva/conoce-aviva/conoce-aviva.component';

import { MisCitasComponent } from './+mis-citas/mis-citas.component';
import { MisDatosComponent } from './+mis-datos/mis-datos.component';
import { MisCitasDetailsComponent } from './+mis-citas/mis-citas-details/mis-citas-details.component';
import { ValidateCodePasswordComponent } from './validate-code-password/validate-code-password.component';

import { AppLayoutConoceAvivaComponent } from './shared/layouts/app-layout-conoce-aviva/app-layout-conoce-aviva.component';

import { TerminosYCondicionesComponent } from './+terminos-condiciones/terminos-y-condiciones/terminos-y-condiciones.component';

import { AppLayoutLandingComponent } from './shared/layouts/app-layout-landing/app-layout-landing.component';
import { RecorridoComponent } from './recorrido/recorrido.component';

import { VideoComponent } from './+video/video/video.component';
import { WaitingComponent } from './+video/waiting/waiting.component';
import { VideoLayoutComponent } from './shared/layouts/video-layout/video-layout.component';
import { MisDependientesComponent } from './+dependientes/mis-dependientes/mis-dependientes.component';
import { MisRecetasComponent } from './+recipes/mis-recetas/mis-recetas.component';
import { TeleconComponent } from './+video/telecon/telecon.component';
import { ResumenComponent } from './+express/resumen/resumen.component';
import { LandingBookingComponent } from './landing-booking/landing-booking.component';
import { PoliticatratamientodatosComponent } from './+terminos-condiciones/politicatratamientodatos/politicatratamientodatos.component';



const routes: Routes = [
  {
    path: '',
    component: AppMainLayoutComponent,
    children: [
      {
        path: '', component: HomeComponent
      }
    ]
  },
  {
    path: 'registrarse',
    component: AppLayoutRegisterComponent,
    loadChildren: () => import('src/app/shared/auth/+register/register.module').then(m => m.RegisterModule)
  },
  {
    path: 'reservas',
    component: AppLayoutReservasComponent,
    loadChildren: () => import('src/app/+reservas/reserva.module').then(m => m.ReservaModule)
  },
  {
    path: 'terminos-y-condiciones',
    component: AppLayoutReservasComponent,
    children: [
      {
        path: '', component: TerminosYCondicionesComponent
      }
    ]
  },
  {
    path: 'politica-tratamiento-datos',
    component: AppLayoutReservasComponent,
    children: [
      {
        path: '', component: PoliticatratamientodatosComponent
      }
    ]
  },
  {
    path: 'mi-aviva',
    component: AppLayoutReservasComponent,
    children: [
      {
        path: '', component: LandingBookingComponent
      }
    ]
  },

  {
    path: 'especialidades-doctores',
    component: AppLayoutPlanesProgramasComponent,
    loadChildren: () => import('src/app/+especialidades-y-doctores/especialidades-doctores.module').then(m => m.EspecialidadesDoctoresModule)
  },

  {
    path: 'planes-programas',
    component: AppLayoutPlanesProgramasComponent,
    loadChildren: () => import('src/app/+planes-programas/planes-programas.module').then(m => m.PlanesProgramasModule)
  },
  {
    path: 'validate-code',
    component: AppLayoutErrorsComponent,
    children: [
      {
        path: '', component: ValidateCodeComponent
      }
    ]
  },
  {
    path: 'recuperar-contraseña/:id',
    component: AppLayoutErrorsComponent,
    children: [
      {
        path: '', component: ValidateCodePasswordComponent
      }
    ]
  },
  {
    path: '404',
    component: AppLayoutErrorsComponent,
    children: [
      {
        path: '', component: Errors404Component
      }
    ]
  },
  {
    path: '500',
    component: Errors500Component
  },
  {
    path: 'reservas/reserva-finalizada',
    component: AppLayoutInternasComponent,
    children: [
      {
        path: '', component: ReservaFinalizadaComponent
      }
    ]
  },
  {
    path: 'avivacuida/reserva-finalizada',
    component: AppLayoutInternasComponent,
    children: [
      {
        path: '', component: ReservaFinalizadaComponent
      }
    ]
  },
  {
    path: 'reservas/reserva-finalizada',
    component: AppLayoutInternasComponent,
    children: [
      {
        path: '', component: ReservaFinalizadaComponent
      }
    ]
  },
  {
    path: 'conoce-aviva',
    component: AppLayoutConoceAvivaComponent,
    children: [
      {
        path: '', component: ConoceAvivaComponent
      }
    ]
  },
  {
    path: 'mis-citas',
    component: AppLayoutPlanesProgramasComponent,
    children: [
      {
        path: '', component: MisCitasComponent
      }
    ]
  },
  {
    path: 'mis-citas/detalles/:id',
    component: AppLayoutPlanesProgramasComponent,
    children: [
      {
        path: '', component: MisCitasDetailsComponent
      }
    ]
  },
  {
    path: 'video/:data',
    component: VideoLayoutComponent,
    children: [
      {
        path: '', component: VideoComponent
      }
    ]
  },
  {
    path: 'telecon/:data',
    component: VideoLayoutComponent,
    children: [
      {
        path: '', component: TeleconComponent
      }
    ]
  },
  {
    path: 'waiting/:data',
    component: VideoLayoutComponent,
    children: [
      {
        path: '', component: WaitingComponent
      }
    ]
  },
  {
    path: 'mis-datos',
    component: AppLayoutPlanesProgramasComponent,
    children: [
      {
        path: '', component: MisDatosComponent
      }
    ]
  },
  {
    path: 'mis-recetas',
    component: AppLayoutPlanesProgramasComponent,
    children: [
      {
        path: '', component: MisRecetasComponent
      }
    ]
  },
  {
    path: 'mis-dependientes',
    component: AppLayoutPlanesProgramasComponent,
    children: [
      {
        path: '', component: MisDependientesComponent
      }
    ]
  },
  {
    path: 'express',
    component: AppLayoutInternasComponent,
    children: [
      {
        path: '', component: ResumenComponent
      }
    ]
  },
  {
    path: 'landing-page/:name',
    component: AppLayoutLandingComponent,
    loadChildren: () => import('src/app/+landing-reservas/landing-reservas.module').then(m => m.LandingReservasModule)
  },
  {
    path: 'promo-primera-cita',
    component: AppLayoutLandingComponent,
    loadChildren: () => import('src/app/+landing-captura-leads/landing-captura-leads.module').then(m => m.LandingCapturaLeadsModule)
  },
  {
    path: 'promo-dental',
    component: AppLayoutLandingComponent,
    loadChildren: () => import('src/app/+promo-dental/promo-dental/promo-dental.module').then(m => m.PromoDentalModule)
  },
  {
    path: 'tour-virtual',
    component: RecorridoComponent,
  },
/*    {
    path: '**',
    redirectTo: '404'
  }   */
]
export const routing: ModuleWithProviders = RouterModule.forRoot(routes);