import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../auth/auth.service';
import { Router } from '@angular/router';
import { MatIconRegistry } from '@angular/material';
import { DomSanitizer } from '@angular/platform-browser';
import { ModalComponent } from '../../modal/modal/modal.component';
import { MatDialog } from '@angular/material';
import { RecoveryComponent } from '../../modal/recovery/recovery.component';

@Component({
  selector: 'app-nav',
  templateUrl: './nav.component.html'
})
export class NavComponent implements OnInit {

  public pagePosition: any;
  public user: any;
  addClassMenu: any;
  public role: string;
  public show: boolean = false;
  constructor(iconRegistry: MatIconRegistry, private AuthService: AuthService, private router: Router, sanitizer: DomSanitizer, public dialog: MatDialog) {

    iconRegistry.addSvgIcon(
      'phone',
      sanitizer.bypassSecurityTrustResourceUrl('assets/svg/telef.svg'));
  }

  ngOnInit() {
    this.AuthService._positionPage.subscribe(data => {
      this.pagePosition = data;
    })
  }

  //toggle sub menu

  showSubMenu() {
    this.show = !this.show;
  }

  isUser() {
    if (this.AuthService.isUser() === 'user') {
      this.user = this.AuthService.User();
      return true
    } else {
      return false
    }
  }

  logout() {
    if (this.AuthService.logout()) {
      this.AuthService.getSesionPublic();
      this.router.navigate(['/'])
    } else {
    }
  }

  changeStateMenu() {
    if (this.addClassMenu === 'activo') {
      this.addClassMenu = '';

    } else {
      this.addClassMenu = 'activo';
    }
  }

  // OPEN MODAL LOGIN
  openLogin(): void {
    const diallogRef = this.dialog.open(ModalComponent, {
      data: 'home',
      maxWidth: '641px',
      width: '97%'
    });
    diallogRef.afterClosed().subscribe(res => {

    })
  }


  openModalChange() {
    this.dialog.open(RecoveryComponent);
  }

}
