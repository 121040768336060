import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material';
import { NavigationEnd, NavigationStart, ResolveEnd, Router } from '@angular/router';
/* import { GoogleTagManagerService } from 'angular-google-tag-manager'; */
import { filter } from 'rxjs/operators';
import { AuthService } from './shared/auth/auth.service';
import { CovidComponent } from './shared/modal/covid/covid.component';
import { environment } from './../environments/environment';
import { RegisterOkComponent } from './shared/modal/register-ok/register-ok.component';
import { StatusComponent } from './shared/modal/status/status.component';


declare let gtag: (property: string, value: any, configs: any) => {};

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'aviva';

  constructor(private auth: AuthService, 
              private router: Router, 
             /*  private gtmService: GoogleTagManagerService, */
              private dialog: MatDialog ) {

    
      /* this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe((e:any) => {
        const gtmTag = {
          event: 'page',
          pageName: e.url
        };
        this.gtmService.pushTag(gtmTag);
      }); */
   /*    this.router.events.subscribe(event => {
        if (event instanceof NavigationEnd) {
          gtag('config', environment.googleAnalyticsId, {
            page_path: event.urlAfterRedirects
          });
        }
      }); */
  }

  ngOnInit() {
   /*  this.openCovid();   */
    this.router.events.subscribe((evt) => {
      if(!(evt instanceof NavigationEnd)){
        return
      }
      window.scrollTo(0,0)
    })
    const local = JSON.parse(localStorage.getItem('session'));
    if (!local) {
      this.auth.getSesionPublic();
    } else { 
      if(local.actualizado){
        return
      }else{
        this.auth.getSesionPublic();
      }
    }

  }

  openCovid(){
    this.dialog.open(CovidComponent,{
      width:'700px',
    });
  }

  welcomeAviva(){
    this.dialog.open(RegisterOkComponent)
  }

  getStatus(){
    this.auth.getStatus().subscribe(data => {
      console.log(data);
    }, err => {
      this.openCovid();
    })
  }
}
