import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material';

import * as moment from 'moment';
import 'moment/locale/es'

MatDialogRef
@Component({
  selector: 'app-covid',
  templateUrl: './covid.component.html',
  styleUrls: ['./covid.component.scss']
})
export class CovidComponent implements OnInit {
  public activadolunes: any;
  public activadomartes: any;
  public activadomiercoles: any;
  public activadojueves: any;
  public activadoviernes: any;
  public activadosabado: any;
  public activadodomingo: any;
  public diasemana: any;
  public diasemana2: any;
  public diasemana3: any;
  public diasemana4: any;
  public diasemana5: any;
  public diasemana6: any;
  public diasemana7: any;

  constructor(public dialogRef: MatDialogRef<CovidComponent>) { }

  ngOnInit() {
   // this.diadesemana();
  this.activadolunes=0;
  this.activadomartes=0;
  this.activadomiercoles=0;
  this.activadojueves=0;
  this.activadoviernes=0;
  this.activadosabado=0;
  this.activadodomingo=0;
  this.diasemana=moment().format('dddd');
  this.diasemana2=moment().add(-1,'days').format('dddd');
  this.diasemana3=moment().add(-2,'days').format('dddd');
  this.diasemana4=moment().add(-3,'days').format('dddd');
  this.diasemana5=moment().add(-4,'days').format('dddd');
  this.diasemana6=moment().add(-5,'days').format('dddd');
  this.diasemana7=moment().add(-6,'days').format('dddd');
  console.log(this.diasemana);
  console.log(this.diasemana2);
  console.log(this.diasemana3);
  console.log(this.diasemana4);
  console.log(this.diasemana5);
  console.log(this.diasemana6);
  console.log(this.diasemana7);
  if(this.diasemana=='lunes'){
    console.log("hoy es lunes");
    this.activadolunes=1;
    // this.activadomartes=0;
    // this.activadomiercoles=0;
    // this.activadojueves=0;
    // this.activadoviernes=0;
    // this.activadosabado=0;
  //  this.activadodomingo=0;
  console.log(this.activadolunes);
  console.log(this.activadomartes);
  console.log(this.activadomiercoles);
  console.log(this.activadojueves);
  console.log(this.activadoviernes);
  console.log(this.activadosabado);
  console.log(this.activadodomingo);
  }else if(this.diasemana=='martes'){
    console.log("hoy es martes");
  //  this.activadolunes=0;
    this.activadomartes=1;
    // this.activadomiercoles=0;
    // this.activadojueves=0;
    // this.activadoviernes=0;
    // this.activadosabado=0;
    // this.activadodomingo=0;
    console.log(this.activadolunes);
    console.log(this.activadomartes);
    console.log(this.activadomiercoles);
    console.log(this.activadojueves);
    console.log(this.activadoviernes);
    console.log(this.activadosabado);
    console.log(this.activadodomingo);
  }else if(this.diasemana=='miércoles'){
    console.log("hoy es miércoles");
    // this.activadolunes=0;
    // this.activadomartes=0;
    this.activadomiercoles=1;
    // this.activadojueves=0;
    // this.activadoviernes=0;
    // this.activadosabado=0;
    // this.activadodomingo=0;
    console.log(this.activadolunes);
  console.log(this.activadomartes);
  console.log(this.activadomiercoles);
  console.log(this.activadojueves);
  console.log(this.activadoviernes);
  console.log(this.activadosabado);
  console.log(this.activadodomingo);
  }else if(this.diasemana=='jueves'){
    console.log("hoy es jueves");
    // this.activadolunes=0;
    // this.activadomartes=0;
    // this.activadomiercoles=0;
    this.activadojueves=1;
    // this.activadoviernes=0;
    // this.activadosabado=0;
    // this.activadodomingo=0;
    console.log(this.activadolunes);
  console.log(this.activadomartes);
  console.log(this.activadomiercoles);
  console.log(this.activadojueves);
  console.log(this.activadoviernes);
  console.log(this.activadosabado);
  console.log(this.activadodomingo);
  }else if(this.diasemana=='viernes'){
    console.log("hoy es viernes");
    // this.activadolunes=0;
    // this.activadomartes=0;
    // this.activadomiercoles=0;
    // this.activadojueves=0;
    this.activadoviernes=1;
    // this.activadosabado=0;
    // this.activadodomingo=0;
    console.log(this.activadolunes);
  console.log(this.activadomartes);
  console.log(this.activadomiercoles);
  console.log(this.activadojueves);
  console.log(this.activadoviernes);
  console.log(this.activadosabado);
  console.log(this.activadodomingo);
  }else if(this.diasemana=='sábado'){
    console.log("hoy es sábado");
    // this.activadolunes=0;
    // this.activadomartes=0;
    // this.activadomiercoles=0;
    // this.activadojueves=0;
    // this.activadoviernes=0;
    this.activadosabado=1;
    // this.activadodomingo=0;
    console.log(this.activadolunes);
  console.log(this.activadomartes);
  console.log(this.activadomiercoles);
  console.log(this.activadojueves);
  console.log(this.activadoviernes);
  console.log(this.activadosabado);
  console.log(this.activadodomingo);
  }else if(this.diasemana=='domingo'){
    console.log("hoy es domingo");
    // this.activadolunes=0;
    // this.activadomartes=0;
    // this.activadomiercoles=0;
    // this.activadojueves=0;
    // this.activadoviernes=0;
    this.activadosabado=1;
    console.log(this.activadolunes);
  console.log(this.activadomartes);
  console.log(this.activadomiercoles);
  console.log(this.activadojueves);
  console.log(this.activadoviernes);
  console.log(this.activadosabado);
  console.log(this.activadodomingo);
  }
 
  }

  onClickNo(){
    this.dialogRef.close();
  }
  diadesemana(){
    this.diasemana=moment().format('dddd');
    this.diasemana2=moment().add(-1,'days').format('dddd');
    this.diasemana3=moment().add(-2,'days').format('dddd');
    this.diasemana4=moment().add(-3,'days').format('dddd');
    this.diasemana5=moment().add(-4,'days').format('dddd');
    this.diasemana6=moment().add(-5,'days').format('dddd');
    this.diasemana7=moment().add(-6,'days').format('dddd');
    console.log(this.diasemana);
    console.log(this.diasemana2);
    console.log(this.diasemana3);
    console.log(this.diasemana4);
    console.log(this.diasemana5);
    console.log(this.diasemana6);
    console.log(this.diasemana7);
    if(this.diasemana=='lunes'){
      console.log("hoy es lunes");
      this.activadolunes=1;
      // this.activadomartes=0;
      // this.activadomiercoles=0;
      // this.activadojueves=0;
      // this.activadoviernes=0;
      // this.activadosabado=0;
    //  this.activadodomingo=0;
    console.log(this.activadolunes);
    console.log(this.activadomartes);
    console.log(this.activadomiercoles);
    console.log(this.activadojueves);
    console.log(this.activadoviernes);
    console.log(this.activadosabado);
    console.log(this.activadodomingo);
    }else if(this.diasemana=='martes'){
      console.log("hoy es martes");
    //  this.activadolunes=0;
      this.activadomartes=1;
      // this.activadomiercoles=0;
      // this.activadojueves=0;
      // this.activadoviernes=0;
      // this.activadosabado=0;
      // this.activadodomingo=0;
      console.log(this.activadolunes);
      console.log(this.activadomartes);
      console.log(this.activadomiercoles);
      console.log(this.activadojueves);
      console.log(this.activadoviernes);
      console.log(this.activadosabado);
      console.log(this.activadodomingo);
    }else if(this.diasemana=='miércoles'){
      console.log("hoy es miércoles");
      // this.activadolunes=0;
      // this.activadomartes=0;
      this.activadomiercoles=1;
      // this.activadojueves=0;
      // this.activadoviernes=0;
      // this.activadosabado=0;
      // this.activadodomingo=0;
      console.log(this.activadolunes);
    console.log(this.activadomartes);
    console.log(this.activadomiercoles);
    console.log(this.activadojueves);
    console.log(this.activadoviernes);
    console.log(this.activadosabado);
    console.log(this.activadodomingo);
    }else if(this.diasemana=='jueves'){
      console.log("hoy es jueves");
      // this.activadolunes=0;
      // this.activadomartes=0;
      // this.activadomiercoles=0;
      this.activadojueves=1;
      // this.activadoviernes=0;
      // this.activadosabado=0;
      // this.activadodomingo=0;
      console.log(this.activadolunes);
    console.log(this.activadomartes);
    console.log(this.activadomiercoles);
    console.log(this.activadojueves);
    console.log(this.activadoviernes);
    console.log(this.activadosabado);
    console.log(this.activadodomingo);
    }else if(this.diasemana=='viernes'){
      console.log("hoy es viernes");
      // this.activadolunes=0;
      // this.activadomartes=0;
      // this.activadomiercoles=0;
      // this.activadojueves=0;
      this.activadoviernes=1;
      // this.activadosabado=0;
      // this.activadodomingo=0;
      console.log(this.activadolunes);
    console.log(this.activadomartes);
    console.log(this.activadomiercoles);
    console.log(this.activadojueves);
    console.log(this.activadoviernes);
    console.log(this.activadosabado);
    console.log(this.activadodomingo);
    }else if(this.diasemana=='sábado'){
      console.log("hoy es sábado");
      // this.activadolunes=0;
      // this.activadomartes=0;
      // this.activadomiercoles=0;
      // this.activadojueves=0;
      // this.activadoviernes=0;
      this.activadosabado=1;
      // this.activadodomingo=0;
      console.log(this.activadolunes);
    console.log(this.activadomartes);
    console.log(this.activadomiercoles);
    console.log(this.activadojueves);
    console.log(this.activadoviernes);
    console.log(this.activadosabado);
    console.log(this.activadodomingo);
    }else if(this.diasemana=='domingo'){
      console.log("hoy es domingo");
      // this.activadolunes=0;
      // this.activadomartes=0;
      // this.activadomiercoles=0;
      // this.activadojueves=0;
      // this.activadoviernes=0;
      this.activadosabado=1;
      console.log(this.activadolunes);
    console.log(this.activadomartes);
    console.log(this.activadomiercoles);
    console.log(this.activadojueves);
    console.log(this.activadoviernes);
    console.log(this.activadosabado);
    console.log(this.activadodomingo);
    }
  }

}
