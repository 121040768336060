import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-errors500',
  templateUrl: './errors500.component.html',
  styleUrls: ['./errors500.component.sass']
})
export class Errors500Component implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
