import { Injectable } from '@angular/core';
import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { Subject } from 'rxjs';
import { AuthService } from '../shared/auth/auth.service';
import { HttpClient, HttpResponse, HttpRequest, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { AngularFirestore } from '@angular/fire/firestore';

@Injectable({
  providedIn: 'root'
})

export class ReservasService {



  _progressObs: Subject<any> = new Subject();
  _progressPage: Subject<any> = new Subject();

  public dataPlansCliente;
  public dataPlansClienteId;
  public prestacion;
  public dateCita;
  public parent;
  public parentId;
  public infoCita;
  public idParent;
  public dependens;
  public financiador;

  public urlPdfWhatssap: string = '';
  public dataJson: any = [];
  public id = 1;
  public provisionsId;
  public dataCreateParent;

  public priceReser: any = '';
  public provisions: any;
  private url = environment.urlBaseAlter;
  public dataPacienteReniec;
  public patientId;
  public dataPaciente;
  public parentIdCreate;
  public bloque;
  public doctorEscogido;
  public errorCita;
  public dataError;
  public doctorDatos;
  public plan;
  constructor(private Authservice: AuthService, 
              private http: HttpClient,
              private afs: AngularFirestore) { }

  urlBaseNodos = this.Authservice.getUrlBaseNodos();
  urlBase = this.Authservice.getUrlBase();
  get progressObs() {
    return this._progressObs;
  }
  get progressPage() {
    return this._progressPage;
  }

  getSpecialty() {
    console.log('data')
    const session = JSON.parse(localStorage.getItem('session'));
    let headers = new HttpHeaders({ "Authorization": session.authorization });
    return this.http
      .get(this.urlBase + 'api/v2/ebooking/fmt-centers/all/services', {headers});
  }

  getSpecialtyTele() {
    console.log('data')
    const tele = 845337
    const session = JSON.parse(localStorage.getItem('session'));
    let headers = new HttpHeaders({ "Authorization": session.authorization });
    return this.http
      .get(this.urlBase + `api/v2/ebooking/fmt-centers/${tele}/services`, {headers});
  }

  getAllDoctors(){
    const session = JSON.parse(localStorage.getItem('session'));
    let headers = new HttpHeaders({ "Authorization": session.authorization });
    return this.http.get(this.urlBase + `api/v2/ebooking/info-profesionales`, {headers}).pipe(
      map( data => {
        return data
      }, err => {
        return err
      })
    )
  }

  getDoctorInfo(id){
    return this.http.get(this.urlBase + `api/v2/ebooking/doctors/getDoctorByProfessionalId/${id}`).pipe(
      map(data => {
        return data
      }, err => {
        return err
      })
    )
  }

  getDoctorsSpecialty(params: any, date1: any, date2: any) {
    const session = JSON.parse(localStorage.getItem('session'));
    let headers = new HttpHeaders({ "Authorization": session.authorization });
    return this.http
      .get(this.urlBase + 'api/v2/ebooking/fmt-centers/1/services/' + params + '/professionals/all/availables?from_date=' + date1 + '&to_date=' + date2, {headers});
  }

  getDoctorsSpecialtyBD(params: any) {
    const session = JSON.parse(localStorage.getItem('session'));
    let headers = new HttpHeaders({ "Authorization": session.authorization });
    return this.http
      .get(this.urlBase + 'api/v2/ebooking/fmt-centers/1/basicservices/' + params + '/professionals/1/info-for-availables', {headers});
  }

  getDoctorsSlotsPerDay(data: any) {
    const session = JSON.parse(localStorage.getItem('session'));
    let headers = new HttpHeaders({ "Authorization": session.authorization });
    let params = data;
    return this.http
      .post(this.urlBase + 'api/v2/ebooking/slots/availables-extra', params, {headers});
  }

  getDoctorsSpecialtyTele(params: any, date1: any, date2: any) {
    const session = JSON.parse(localStorage.getItem('session'));
    let headers = new HttpHeaders({ "Authorization": session.authorization });

    return this.http
      .get(this.url + `api/v2/ebooking/fmt-centers/1/basicservices/${params}/professionals/all/provision/845337/availables?from_date=${date1}&to_date=${date2}`, { headers });
  }

  createAppoitment() {
    console.log(this.provisionsId)
    console.log(this.dataJson)
    const session = JSON.parse(localStorage.getItem('session'));
    let headers = new HttpHeaders({ "Authorization": session.authorization });
    this.dataJson.provisions = [{ "default": false, "id": this.provisionsId }];
    return this.http
      .post(this.urlBase + 'api/v2/ebooking/appointments/create/web', this.dataJson, {headers});
  }

  createParentDate() {
    console.log('dataJson en createParent', this.dataJson);
    const session = JSON.parse(localStorage.getItem('session'));
    let headers = new HttpHeaders({ "Authorization": session.authorization });
    let params = this.dataJson;
    this.dataJson.provisions = [{ "default": false, "id": this.provisionsId }];

    return this.http.post(this.urlBase + `api/v2/ebooking/appointments/createForUser/${this.parentIdCreate}/web`, params, { headers }).pipe(
      map((resp: any) => {
        return resp;
      })
    )
  }

  startPayCulqi(data: any) {
    const session = JSON.parse(localStorage.getItem('session'));
    let headers = new HttpHeaders({ "Authorization": session.authorization });
    return this.http
      .post(this.urlBase + 'api/v2/ebooking/culqi-charges', data, {headers});
  }

  getPlansFinanciador(service_id, doctorId, fecha) {
    const session = JSON.parse(localStorage.getItem('session'));
    let headers = new HttpHeaders({ "Authorization": session.authorization });
    return this.http
      .get(this.urlBase + 'api/v2/ebooking/planes-paciente-precio-prestacion?center_id=1&servicio_id=' + service_id + '&prestacion_id=' + this.provisionsId + '&medico_id=' + doctorId + '&fecha=' + fecha + ' ', {headers})
  }

  getplanesContacto(paciente_id, servicio_id, prestacion_id, medico_id, proposed_date) {
    const session = JSON.parse(localStorage.getItem('session'));
    let headers = new HttpHeaders({ "Authorization": session.authorization });

    return this.http.get(this.urlBase + `api/v2/ebooking/planes-paciente-contacto-precio-prestacion?paciente_id=${paciente_id}&servicio_id=${servicio_id}&prestacion_id=${this.provisionsId}&medico_id=${medico_id}&fecha=${proposed_date}`, { headers }).pipe(
      map(data => {
        return data
      })
    )
  }

  delteCita(cita) {
    console.log('delete cita principal', cita)
    const appointmentId = cita.appointmentId;
    const session = JSON.parse(localStorage.getItem('session'));
    let headers = new HttpHeaders({ "Authorization": session.authorization });
    return this.http
      .delete(this.urlBase + 'api/v2/ebooking/appointments/' + appointmentId + ' ', {headers})
  }
  eliminacioncitarapida(citaid: any, pacienteid: any){
    return this.http.delete(this.urlBase + `api/v2/ebooking/appointmentsNoAutho/`+ citaid + `/` + pacienteid).pipe(
      map((resp: any) => {
        return resp;
      })
    )
  }
  detallecita(patientId: any, specialityId: any, centerId: any){
    return this.http.get(this.urlBase + `api/v2/ebooking-mssql/getInfoReservas?patientId=`+ patientId + `&codEspecialidad=` + specialityId + `&codCentro=` + centerId).pipe(
      map((resp: any) => {
        return resp;
      })
    )
  }

  destroyAppointmentContact(appointment) {
    console.log('delete cita parent:', appointment);
    const patientId = appointment.patientId;
    const appointmentId = appointment.appointmentId;
    console.log('lo que se va a eliminar cita fmiliar', patientId, appointmentId);
    const session = JSON.parse(localStorage.getItem('session'));
    let headers = new HttpHeaders({ "Authorization": session.authorization });

    return this.http.delete(this.urlBase + `api/v2/ebooking/appointments/patient-contacts/${patientId}/${appointmentId}/`, { headers }).pipe(
      map((resp: any) => {
        return resp;
      })
    )
  }

  createParent(data) {
    const session = JSON.parse(localStorage.getItem('session'));
    let headers = new HttpHeaders({ "Authorization": session.authorization });
    let params = data;

    return this.http.post(this.urlBase + `api/v2/users/register-dependent/`, params, { headers }).pipe(
      map((resp: any) => {
        return resp;
      })
    )
  }

  createParentNoAuthoWeb(data) {
    const dataRegistro = JSON.parse(localStorage.getItem('session'));
    if(!this.patientId){
      const dataRegistro = JSON.parse(localStorage.getItem('session'));
      this.patientId = dataRegistro.patientId;
      console.log("old id del paciente",this.parentId)
    }else{
      this.patientId = this.patientId;
      console.log("id del paciente",this.parentId)
    }
    
    let params = data;
    console.log(params)
    console.log("esta si es un patientid",this.parentId)
    return this.http.post(this.urlBase + `api/v2/users/register-dependentNoAutho/${this.patientId}`, params).pipe(
      map((resp: any) => {
        return resp;
      })
    )
  } 

  validateCita() {
    const session = JSON.parse(localStorage.getItem('session'));
    let headers = new HttpHeaders({ "Authorization": session.authorization });
    this.dataJson.provisions = [{ "default": false, "id": `${this.provisionsId}` }];
    return this.http
      .post(this.urlBase + 'api/v2/ebooking/appointments/validate', this.dataJson, {headers})
  }

  saveCitaNod(data) {
    const session = JSON.parse(localStorage.getItem('session'));
    let headers = new HttpHeaders({ "Authorization": session.authorization });
    return this.http
      .post(this.urlBaseNodos + 'appointments/reserve', data, {headers})
  }

  getPdf() {
    const session = JSON.parse(localStorage.getItem('session'));
    let headers = new HttpHeaders({ "Authorization": session.authorization });
    return this.http
      .post(this.urlPdfWhatssap, this.id, {headers})
  }

  getDoctorsPerId(id) {
    const authorization = localStorage.getItem('authorization');
    let headers = new HttpHeaders({ "Authorization": authorization });
    const center_id = 1;

    return this.http.get(this.urlBase + `ebooking/fmt-centers/${center_id}/services/${id}/professionals`, { headers }).pipe(
      map((resp: any) => {
        return resp
      })
    )
  }

  chekstatusAppointment(appointmentId) {
    const authorization = localStorage.getItem('authorization');
    let headers = new HttpHeaders({ "Authorization": authorization });

    return this.http.get(this.urlBase + `api/v2/ebooking/appointments/${appointmentId}/status`, { headers }).pipe(
      map(resp => {
        return resp
      })
    )
  }


  chekstatusAppointmentParent(patientId, appointmentId) {
    const authorization = localStorage.getItem('authorization');
    let headers = new HttpHeaders({ "Authorization": authorization });

    return this.http.get(this.urlBase + `api/v2/ebooking/appointments-contact/${patientId}/${appointmentId}/status`, { headers }).pipe(
      map(resp => {
        return resp
      })
    )
  }

  confirmDate(appointmentId) {
    console.log('appointment en servicio confirm',appointmentId);
    const authorization = localStorage.getItem('authorization');
    let headers = new HttpHeaders({ "Authorization": authorization });
    /* let params = [{data:'data'}]; */

    return this.http.post(this.urlBase + `api/v2/ebooking/appointments/${appointmentId}/confirm`, { headers }).pipe(
      map(resp => {
        return resp
      })
    )
  }

  confirmDateParent(patientId, appointmentId) {
    const authorization = localStorage.getItem('authorization');
    let headers = new HttpHeaders({ "Authorization": authorization });
    let params = "";

    return this.http.post(this.urlBase + `api/v2/ebooking/appointments-contact/${patientId}/${appointmentId}/confirm`, params, { headers }).pipe(
      map(resp => {
        return resp
      })
    )
  }

  getdependesNoAutho() {
    if(!this.patientId){
      const dataRegistro = JSON.parse(localStorage.getItem('session'));
      this.patientId = dataRegistro.patientId;
      console.log("old id del paciente",this.parentId)
    }else{
      this.patientId = this.patientId;
      console.log("id del paciente",this.parentId)
    }
   return this.http.get(this.urlBase + `api/v2/users/dependentsNoAutho/` + this.patientId).pipe(
     map(data => {
       return data;
     })
   )
 }

 getPlansFinanciadorNoAutho(service_id, doctorId, fecha) {
      return this.http
        .get(this.urlBase + 'api/v2/ebooking/planes-paciente-precio-prestacionNoAutho?center_id=1&servicio_id=' + service_id + '&prestacion_id=' + this.provisionsId + '&medico_id=' + doctorId + '&fecha=' + fecha + '&patientId=' + this.patientId)
    }

    getplanesContactoNoAutho(paciente_id, servicio_id, prestacion_id, medico_id, proposed_date) {
       return this.http.get(this.urlBase + `api/v2/ebooking/planes-paciente-contacto-precio-prestacionNoAutho?paciente_id=${paciente_id}&servicio_id=${servicio_id}&prestacion_id=${this.provisionsId}&medico_id=${medico_id}&fecha=${proposed_date}&contactId=${this.parentId}` ).pipe(
         map(data => {
           return data
         }) 
       )
     }
     createAppoitmentNoAutho() {
      this.patientId
      this.dataJson.provisions = [{ "default": false, "id": this.provisionsId }];
      let params = {listJson:this.dataJson};
      return this.http
        .post(this.urlBase + 'api/v2/ebooking/appointments/createNoAutho/'+ this.patientId +'/web', params).pipe(
          map(resp => {
            return resp
          })
        ) ;
    }

     createAppoitmentNoAuthoMaterno() {
      this.patientId
      console.log('this.patientId en create:',this.patientId)
      this.dataJson.provisions = [{ "default": false, "id": this.provisionsId }];
      this.dataJson.payer = {"id": 1 }; 
      this.dataJson.plan = {"id": this.plan };
      let params = {listJson:this.dataJson};
      return this.http
        .post(this.urlBase + 'api/v2/ebooking/appointments/createNoAutho/'+ this.patientId + '/web' , params).pipe(
          map(resp => {
            return resp
          })
        ) ;
    }
  
    createParentNoAutho(){
      this.dataJson.provisions = [{ "default": false, "id": this.provisionsId }];
      let params = {listJson:this.dataJson};
      return this.http
        .post(this.urlBase + 'api/v2/ebooking/appointments/createforuserNoAutho/' + this.parentId + '/web' , params).pipe(
          map(resp => {
            return resp
          })
        ) ;
    }

    getdependesNoAuthoParent() {
      console.log("dependents", this.parentId)
      return this.http.get(this.urlBase + `users/dependentsNoAutho/` + this.patientId).pipe(
        map(data => {
          return data;
        })
      )
    }

    saveDataCreate(data){
      const id = data.appointmentId;
      console.log('servicio',data,id);
      return this.afs.collection('creacionCitas').doc(id.toString()).set({
        data
      },{merge: true})
    }
  

}