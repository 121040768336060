import { Component, OnInit } from '@angular/core';


@Component({
  selector: 'app-app-main-layout',
  templateUrl: './app-main-layout.component.html',
  styleUrls: ['./app-main-layout.component.sass']
})
export class AppMainLayoutComponent implements OnInit {
  public user: any;
  public show: boolean;
  constructor() { }

  ngOnInit() {
  }

}
