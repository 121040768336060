import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material'
import { AuthService } from '../../auth/auth.service';
import { Router } from '@angular/router';



@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.sass']
})


export class NotificationComponent implements OnInit {

 
  

  constructor(public dialogRed: MatDialogRef <NotificationComponent>, @Inject(MAT_DIALOG_DATA) public data: any, public AuthService : AuthService, public router : Router) { 
    
  }

  ngOnInit() {

  }

  onClickNo(){
    this.dialogRed.close();
  }
  

}





