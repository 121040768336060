import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-recorrido',
  templateUrl: './recorrido.component.html',
  styleUrls: ['./recorrido.component.css']
})
export class RecorridoComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

  backLink(){
    window.history.back();
  }

}
