import { BrowserModule } from '@angular/platform-browser';
import { LOCALE_ID, NgModule, ApplicationRef } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { routing } from './app.routing';
import { AppComponent } from './app.component';
import { NavComponent } from './shared/+nav/nav/nav.component';
import { NavInternoComponent } from './shared/+nav/nav-interno/nav-interno.component';
import { TextMaskModule } from 'angular2-text-mask';

import { LazyLoadImageModule, LAZYLOAD_IMAGE_HOOKS, ScrollHooks } from 'ng-lazyload-image'; // <-- include ScrollHooks

// IMPORT SERVICES
import { AuthService } from './shared/auth/auth.service';

// IMPORT GUARDS
import { AuthGuard } from './shared/auth/guard/auth.guard';
import { UserGuard } from './shared/auth/guard/user.guard';
import { desactivateUser } from './shared/auth/guard/desactiva.guard';

// IMPORT INTERCEPTOR
import { AuthInterceptor } from './shared/auth/auth.interceptor';

// IMPORT ANIMATION BROWSER
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

// IMPORT COMPONENT
import { HomeComponent } from './+home/home.component';
import { FooterComponent } from './shared/footer/footer.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

//IMPOR LAYOUTS FOR ALL APLICATION
import { AppMainLayoutComponent } from './shared/layouts/app-main-layout/app-main-layout.component';
import { AppLayoutLoginComponent } from './shared/layouts/app-layout-login/app-layout-login.component';
import { AppLayoutRegisterComponent } from './shared/layouts/app-layout-register/app-layout-register.component';
import { AppLayoutReservasComponent } from './shared/layouts/app-layout-reservas/app-layout-reservas.component';
import { AppLayoutPagoFinalizadoComponent } from './shared/layouts/app-layout-pago-finalizado/app-layout-pago-finalizado.component';


// ANGULAR MATERIAL
import { MatButtonModule, MatCheckboxModule, MAT_DIALOG_DATA } from '@angular/material';
import { MatDialogModule } from '@angular/material/dialog';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatDividerModule } from '@angular/material/divider';
import { MatTabsModule } from '@angular/material/tabs';
import { MatExpansionModule } from '@angular/material/expansion';
import { ModalComponent } from './shared/modal/modal/modal.component';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatIconModule} from '@angular/material/icon';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatInputModule} from '@angular/material/input';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {MatCardModule} from '@angular/material/card';


import { RegisterModalComponent } from './shared/modal/register-modal/register-modal.component';
import { ReservaFinalizadaComponent } from './+reserva-finalzada/reserva-finalizada/reserva-finalizada.component';
import { AppLayoutInternasComponent } from './shared/layouts/app-layout-internas/app-layout-internas.component';
import { AppLayoutErrorsComponent } from './shared/layouts/app-layout-errors/app-layout-errors.component';


// ERRORS COMPONENT
import { Errors404Component } from './+errors/404/errors404.component';
import { Errors500Component } from './+errors/500/errors500.component';
import { AppLayoutPlanesProgramasComponent } from './shared/layouts/app-layout-planes-programas/app-layout-planes-programas.component';
import { alertComponent } from './shared/modal/alert/alert.component';
import { ValidateCodeComponent } from './validate-code/validate-code.component';
import { ConoceAvivaComponent } from './+conoce-aviva/conoce-aviva/conoce-aviva.component';
import { MisCitasDetailsComponent } from './+mis-citas/mis-citas-details/mis-citas-details.component';

// IMPORT SWIPPER

import { SwiperModule } from 'ngx-swiper-wrapper';
import { SWIPER_CONFIG } from 'ngx-swiper-wrapper';
import { SwiperConfigInterface } from 'ngx-swiper-wrapper';

import { MisCitasComponent } from './+mis-citas/mis-citas.component';
import { MisDatosComponent } from './+mis-datos/mis-datos.component';
import { ModalReservaPlanesComponent } from './shared/modal/modal-reserva-planes/modal-reserva-planes.component';
import { AlertCitasComponent } from './shared/modal/alert-citas/alert-citas.component';
import { ValidateCodePasswordComponent } from './validate-code-password/validate-code-password.component';
import { NotificationComponent } from './shared/modal/notification/notification.component';
import { AppLayoutConoceAvivaComponent } from './shared/layouts/app-layout-conoce-aviva/app-layout-conoce-aviva.component';

import { ModalPlanesProgramasComponent } from './shared/modal/modal-planes-programas/modal-planes-programas.component';

import { ModalDetailsDoctorsComponent } from './shared/modal/modal-details-doctors/modal-details-doctors.component';
import { TerminosYCondicionesComponent } from './+terminos-condiciones/terminos-y-condiciones/terminos-y-condiciones.component';

/* import { AgmCoreModule } from '@agm/core'; */

import { DoctorsListModule } from './+especialidades-y-doctores/doctors-list/doctors-list.module';
import { AppLayoutLandingComponent } from './shared/layouts/app-layout-landing/app-layout-landing.component';
import { NavLandingComponent } from './shared/+nav/nav-landing/nav-landing.component';
import { FormpreComponent } from './shared/modal/formpre/formpre.component';
import { RecorridoComponent } from './recorrido/recorrido.component';
import { VideoComponent } from './+video/video/video.component';
import { WaitingComponent } from './+video/waiting/waiting.component';
import { NgxAgoraModule } from 'ngx-agora';
import { environment } from 'src/environments/environment';
import { VideoLayoutComponent } from './shared/layouts/video-layout/video-layout.component';
import { CreateParentComponent } from './shared/modal/create-parent/create-parent.component';
import { MisDependientesComponent } from './+dependientes/mis-dependientes/mis-dependientes.component';
import { DeleteParentComponent } from './shared/modal/delete-parent/delete-parent.component';
import { MisRecetasComponent } from './+recipes/mis-recetas/mis-recetas.component';
import { NoCancelComponent } from './shared/modal/no-cancel/no-cancel.component';
/* import { GoogleTagManagerModule, GoogleTagManagerService } from 'angular-google-tag-manager'; */

//Firebase
import { AngularFireModule } from '@angular/fire';
import { ErrorPaymentComponent } from './shared/modal/error-payment/error-payment.component';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { RecoveryComponent } from './shared/modal/recovery/recovery.component';
import { TeleconComponent } from './+video/telecon/telecon.component';
import { ResumenComponent } from './+express/resumen/resumen.component';
import { CovidComponent } from './shared/modal/covid/covid.component';

import { QRCodeModule } from 'angular2-qrcode';
import { LandingBookingComponent } from './landing-booking/landing-booking.component';
import { PoliticatratamientodatosComponent } from './+terminos-condiciones/politicatratamientodatos/politicatratamientodatos.component';
import { LoginOkComponent } from './shared/modal/login-ok/login-ok.component';
import { RegisterOkComponent } from './shared/modal/register-ok/register-ok.component';
import { registerLocaleData } from '@angular/common';
import localeEs from '@angular/common/locales/es';
import { BnNgIdleService } from 'bn-ng-idle'; 
import { StatusComponent } from './shared/modal/status/status.component';
import { DeteccioncitaComponent } from './shared/modal/deteccioncita/deteccioncita.component';
registerLocaleData(localeEs, 'es');

/* import { AngularFireStorageModule } from '@angular/fire/storage'; */

export const DEFAULT_SWIPER_CONFIG: SwiperConfigInterface = {

  direction: 'horizontal',
  slidesPerView: 3,
  loop: false,
  navigation: {
    nextEl: '.swiper-button-next',
    prevEl: '.swiper-button-prev',
  },
  pagination: {
    clickable: true,
    el: '.swiper-pagination',
    type: 'bullets',
    hideOnClick: false
  },
  spaceBetween: 50,
  breakpoints: {

    // when window width is <= 640px
    768: {
      slidesPerView: 2,
      spaceBetween: 10,
    }
  }
};


const GUARDS_APLICATION = [
  AuthGuard,
  UserGuard,
  desactivateUser
];

const APP_INTERCEPTOR_PROVIDERS = [{
  provide: HTTP_INTERCEPTORS,
  useClass: AuthInterceptor,
  multi: true,
}];

@NgModule({
  declarations: [
    NavInternoComponent,
    AppComponent,
    NavComponent,
    HomeComponent,
    FooterComponent,
    AppMainLayoutComponent,
    AppLayoutLoginComponent,
    AppLayoutRegisterComponent,
    AppLayoutReservasComponent,
    AppLayoutPagoFinalizadoComponent,
    ModalComponent,
    Errors404Component,
    RegisterModalComponent,
    ReservaFinalizadaComponent,
    AppLayoutInternasComponent,
    AppLayoutErrorsComponent,
    Errors500Component,
    AppLayoutPlanesProgramasComponent,
    alertComponent,
    ValidateCodeComponent,
    ConoceAvivaComponent,
    MisCitasComponent,
    MisDatosComponent,
    MisCitasDetailsComponent,
    ModalReservaPlanesComponent,
    AlertCitasComponent,
    ValidateCodePasswordComponent,
    NotificationComponent,
    AppLayoutConoceAvivaComponent,
    ModalPlanesProgramasComponent,
    ModalDetailsDoctorsComponent,
    TerminosYCondicionesComponent,
    AppLayoutLandingComponent,
    NavLandingComponent,
    FormpreComponent,
    RecorridoComponent,
    VideoComponent,
    WaitingComponent,
    VideoLayoutComponent,
    CreateParentComponent,
    MisDependientesComponent,
    DeleteParentComponent,
    MisRecetasComponent,
    NoCancelComponent,
    ErrorPaymentComponent,
    RecoveryComponent,
    TeleconComponent,
    ResumenComponent,
    CovidComponent,
    LandingBookingComponent,
    PoliticatratamientodatosComponent,
    LoginOkComponent,
    RegisterOkComponent,
    StatusComponent,
    DeteccioncitaComponent
  ],
  imports: [
    BrowserModule,
    routing,
    HttpClientModule,
    BrowserAnimationsModule,
    FormsModule,
    MatButtonModule,
    QRCodeModule,
    MatProgressSpinnerModule,
    MatDividerModule,
    MatDialogModule,
    MatCheckboxModule,
    MatTabsModule,
    MatIconModule,
    MatAutocompleteModule,
    MatInputModule,
    MatSnackBarModule,
    SwiperModule,
    MatExpansionModule,
    MatCardModule,
    DoctorsListModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    AngularFireModule.initializeApp(environment.firebaseConfig),
    AngularFirestoreModule,
    NgxAgoraModule.forRoot({ AppID: environment.agora.appId }),
    LazyLoadImageModule,
    TextMaskModule,
  /*   GoogleTagManagerModule.forRoot({
      id: 'GTM-5T6S5WG',
    }) */
    /*  AgmCoreModule.forRoot({
       apiKey: 'AIzaSyDXW-8c-BgrOr9rlM3Pmg_du0YY0nX6Mmo',
       libraries: ['places']
     }) */
  ],
  entryComponents:
    [ModalComponent,
      RegisterModalComponent,
      alertComponent,
      ModalReservaPlanesComponent,
      NotificationComponent,
      AlertCitasComponent,
      ModalPlanesProgramasComponent,
      ModalDetailsDoctorsComponent,
      DeleteParentComponent,
      NoCancelComponent,
      ErrorPaymentComponent,
      RecoveryComponent,
      CovidComponent,
      DeteccioncitaComponent,
      LoginOkComponent,
      RegisterOkComponent,
      StatusComponent,
      CreateParentComponent],
  exports: [
    MatProgressSpinnerModule,
    MatDividerModule,
    MatTabsModule,
    AppMainLayoutComponent,
    MatDialogModule
  ],
  providers: [
   
    AuthService,
    {provide:LOCALE_ID,useValue:'es'},
    GUARDS_APLICATION,
    APP_INTERCEPTOR_PROVIDERS,
    {
      provide: SWIPER_CONFIG,
      useValue: DEFAULT_SWIPER_CONFIG
    },
    { provide: LAZYLOAD_IMAGE_HOOKS, useClass: ScrollHooks }
   /*  GoogleTagManagerService,
    {provide: 'googleTagManagerId',  useValue: 'GTM-5T6S5WG'} */
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(public appRef: ApplicationRef) { }
}