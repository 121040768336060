import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-formpre',
  templateUrl: './formpre.component.html',
  styleUrls: ['./formpre.component.sass']
})
export class FormpreComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
