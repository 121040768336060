import { Component, OnInit } from '@angular/core';
import { ReservasService } from '../../+reservas/reservas.service';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { BrowserModule, DomSanitizer } from '@angular/platform-browser';
/* import { GoogleTagManagerService } from 'angular-google-tag-manager'; */
import { ErrorPaymentComponent } from 'src/app/shared/modal/error-payment/error-payment.component';
import { MatDialog } from '@angular/material';


@Component({
  selector: 'app-reserva-finalizada',
  templateUrl: './reserva-finalizada.component.html',
  styleUrls: ['./reserva-finalizada.component.scss']
})
export class ReservaFinalizadaComponent implements OnInit {
  public dataPlans: any;
  public stateNav = false;
  public preloader = true;

  public color: any = 'accent';
  public mode: any = 'indeterminate';

  public LinkWhatssap;
  public urlData = [
    {
      href: '',
    }
  ];
  public dataService: any = {
    r_fecha: '',
    hour: ''
  };
  public type;
  public bloque;
  public errorCulqi;
  public dataError;
  constructor(private sanitizer: DomSanitizer, 
              private router: Router, 
              private reservasService: ReservasService, 
              public dialog: MatDialog) { }

  ngOnInit() {
    this.bloque = this.reservasService.bloque;
    this.errorCulqi = this.reservasService.errorCita;
    console.log(this.errorCulqi)
    /* const data = {
      page: 'aviva-cura',
      redirect: false,
      button: false,
      message: ' Tu cita ha sido reservada, recuerda revisar el estado de tus citas en "MIS CITAS", haciendo login'
    }
    if(this.errorCulqi !== true){
      this.openErrorData(data)
    } */
    this.preloader = true;
    if (this.reservasService.dataJson.length === 0) {
      this.router.navigate(['/'])
    } else {
      console.log('lo que viene de reservasService.dataJson:', this.reservasService.dataJson);
      console.log('lo que viene de dataService:', this.dataService);
      this.type = this.reservasService.dataJson.provisions[0].id;
      this.dataService = this.reservasService.dataJson;
      this.reservasService.getPdf().subscribe((data: any) => {
        this.urlData = data.data.links;
        this.LinkWhatssap = this.sanitizer.bypassSecurityTrustResourceUrl(this.urlData[1].href);
      }, (error: any) => {
      })
      this.dataService.r_fecha = moment(this.dataService.appointmentDateTime).locale('es').format('LL');
      this.dataService.hour = moment(this.dataService.appointmentDateTime).locale('es').format('h:mm a');
      this.preloader = false;
    }
  }

/*   tag() {
    const gtmTag = {
      event: 'page',
      pageName: 's'
    };
    this.gtmService.pushTag(gtmTag);
    
  } */

  openErrorData(data) {
    this.dialog.open(ErrorPaymentComponent, { data: data })
  }
  return(){
    this.router.navigate(['/'])
  }

  

}
