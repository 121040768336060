import { Component, OnInit } from '@angular/core';
import { RegisterService } from '../shared/auth/+register/register.service';
import { Router } from '@angular/router';
import { ReservasService } from '../+reservas/reservas.service';
import { AuthService } from '../shared/auth/auth.service';
import { MatDialog } from '@angular/material';

@Component({
  selector: 'app-validate-code',
  templateUrl: './validate-code.component.html',
  styleUrls: ['./validate-code.component.sass']
})
export class ValidateCodeComponent implements OnInit {

  progressPage = {
    page : 'avivaCuida',
    state :  'registro',
    pageDoctor : true
  }

  public color: any = 'warn';
  public mode: any = 'indeterminate';
  public loader: boolean = false;


  public data: any;
  public date1: string = '';
  public date2: string = '';
  public date3: string = '';
  public date4: string = '';
  public codeValidate: any = '';
  public serviceError : boolean = false;

  public sucess: boolean = false;
  public emailHash;

  constructor(public dialog: MatDialog, 
              public reservasService :ReservasService, 
              private registerService: RegisterService, 
              private Router: Router, 
              private Auth: AuthService) { }

  ngOnInit() {
    if(this.Auth.emailHash){
      this.emailHash = this.Auth.emailHash;
    }else{
      this.emailHash = " ";
    }
    console.log(this.emailHash);
    this.reservasService._progressPage.next(this.progressPage);
  }

  
  val(){

   
    if(this.date1.length && this.date2.length && this.date3.length && this.date4.length){
      return false
    }else {
      return true
    }
  }

  valida(){

    const a = <HTMLElement>document.querySelector('[tabindex="2"]');
    const b = <HTMLElement>document.querySelector('[tabindex="3"]');
    const c = <HTMLElement>document.querySelector('[tabindex="4"]');
    if(this.date1 != ''){
      a.focus();
    }
    if(this.date2 != ''){
      b.focus();
    } 
    if(this.date3 != ''){
      c.focus();
    }
  }

  
  
    sendResssgister(){
      this.codeValidate = this.date1 + this.date2 + this.date3 + this.date4;
      console.log(this.codeValidate)
      this.loader = true;
      this.registerService.registerNewUser(this.codeValidate)
      .subscribe((data:any)=>{
          if(data.patientName){
            console.log('existe patieName')
            localStorage.setItem('session', JSON.stringify(data));
            if(this.Auth.isUser()){
              this.Router.navigate(['/']);
            }
          }else{
            console.log('no existe patienName')
          }
      }, error => {
  
        if(error.error.status === 400){
          this.loader = false;
          this.serviceError = true;
              this.date1 = '';
              this.date2 = '';
              this.date3 = '';
              this.date4 = '';
            }
            else if(error.error.status === 409){
              this.loader = false;
              console.log('ya se a registrado con este correo')
              this.serviceError = true;
                  this.date1 = '';
                  this.date2 = '';
                  this.date3 = '';
                  this.date4 = '';
                }
        console.log(error)
      })
    }
    sendAgainEmail(){
      this.loader = true;
      this.registerService.sendCode(this.registerService.data.email).subscribe((data: any) => {
        console.log(data)
        this.loader = false;
      })
    }
}
